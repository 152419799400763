@import url(https://fonts.googleapis.com/css?family=Lato);
/* FONTS */
@font-face {
  font-family: "Avenir-Black";
  src: local("Avenir-Black"), url(/static/media/Avenir-Black.29e92f60.ttf) format("truetype");
}

@font-face {
  font-family: "Avenir-Book";
  src: local("Avenir-Book"), url(/static/media/Avenir-Book.640d11bf.ttf) format("truetype");
}

@font-face {
  font-family: "Avenir-Roman";
  src: local("Avenir-Roman"), url(/static/media/Avenir-Roman.87e30c27.ttf) format("truetype");
}

* {
  box-sizing: border-box;
  position: relative;
  transition: all 0.3s ease;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000
}

main {
  width: 100%;
  min-height: calc(1vh * 100);
  min-height: calc(var(--vh, 1vh) * 100);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.sxl_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #141414;
}


.sxl_wrapper_home {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #141414;
}

.sxl_container {
  max-width: 991px;
  width: 100%;
  padding: 0 15px;
}

/* table */
.sxl_table {
  width: 100% !important;
  border-collapse: collapse;
}

.sxl_table th {
  font-family: "Avenir-Roman";
  font-size: 14px;
  color: #AFAFAF;
  font-weight: 400;
}

.sxl_table_name,
.sxl_table_value,
.sxl_table_team {
  font-family: "Avenir-Roman";
  font-size: 16px;
  color: #ffffff;
  padding-bottom: 14px;
  padding-top: 14px;
}

.sxl_table_name {
  text-decoration: underline;
  cursor: pointer;
}

.sxl_table_team {
  display: flex;
  align-items: center;
}

.sxl_table_team img {
  margin-right: 6px;
  max-width: 22px;
  max-height: 22px;
}

.sxl_table_border_bottom {
  border-bottom: 1px solid #5c533b
}

.sxl_table_border_bottom:last-of-type {
  border-bottom: 1px solid transparent
}

.sxl_row_checked {
  background-image: linear-gradient(to left, #a38e602f, transparent);
}

/* INPUT SEARCH */
.sxl_input_holder {
  position: relative;
  width: 100%;
}

.sxl_input_holder input {
  padding: 8px 8px 8px 40px;
  width: 100%;
  border-radius: 40px;
  background-color: #54585c69;
  border: 1px solid transparent;
  color: white;
  font-family: "Avenir-Roman";
  font-size: 16px;
  line-height: 22px;
  outline: none;
  transition: all .3s ease;
}

.sxl_input_holder input:focus,
.sxl_input_holder input:active {
  border: 1px solid #141414;
}

.sxl_input_holder img {
  position: absolute;
  left: 13px;
  top: 50%;
  transform: translateY(-50%);
  width: 17px;
  cursor: pointer;
}

/* INPUT SEARCH END */

/* CUSTOM CHECKBOX */
/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  /* overflow: hidden; */
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 1px solid #C3C3C3;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
/* .container:hover input ~ .checkmark {
  background-color: #A38E60
  border: 1px solid #A38E60 
} */

/* When the checkbox is checked, add a green background 
.container input:checked~.checkmark {
  background-color: #A38E60
  border: 1px solid #A38E60
} */
/*
/* Create the checkmark/indicator (hidden when not checked) *
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
} */

/* Show the checkmark when checked 
.container input:checked~.checkmark:after {
  display: block;
}  */

.checkedMark {
  background-color: #A38E60;
  border: 1px solid #A38E60;
  content: "";
  position: absolute;
  display: block;
}

.checkedArrow {
  position: absolute;
  left: 9px;
  top: 4px;
  width: 8px;
  height: 14px;
  border: solid rgb(255, 255, 255);
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

/* Style the checkmark/indicator 
.container .checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}*/

/* CUSTOM CHECKBOX END */

/* SIMPLEBAR STYLE */
.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  background: #A38E60
}

.simplebar-track.simplebar-vertical {
  right: -10px;
}

/* SIMPLEBAR STYLE END */

/* Alert button */

.alertButton {
  background-color: #A38E60;
  color: white;
  font-size: 10pt;
  padding: 5px 20px;
  border-radius: 5px;
  margin-right: 25px;
}

.buttonsHolder {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: center;
}

.submitted_vote {
  background-color: white;
  border-radius: 15px;
  width: 350px;
  text-align: center;
  height: 150px;
  padding-top: 35px;
  box-shadow: 0 5px 10px #acacac;
}

/* ContainerStyle */
.containerStyle {
  width: 100vw;
  margin: auto;
  height: 18px;
  background-color: red;
  text-align: center;
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
}

/* Spinner */

.spinerClass {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(70, 180, 85, 0.178);
  width: 100vw;
  height: 100vh;
  z-index: 101;
}

/* NOT FOUND */
.notFound {
  position: center;
  display: flex;
  text-align: center;
  flex-direction: column;
}

/* MEDIA */
@media only screen and (max-width: 767px) {
  .sxl_container {
    padding: 0;
  }
}

/* RULES MODAL */
.modalDisclamer {
  background-color: #141414;
  border-radius: 10px;
  color: #5c5c5c;
  margin: auto;
  overflow: auto;
  max-height: 90vh;
  width: 75%;
  margin-top: 50px;
  outline: none;
}

.modalDisclamer>div {
  padding: 10px;
}

.disclaimer {
  text-align: center;
  margin-top: 20px;
  clear: both;
  float: left;
  width: 100%;
}

.disclaimer a {
  color: #b8e080;
  cursor: pointer;
}

.ReactModal__Overlay {
  z-index: 100 !important;
}

/* Carousel */

.carusel {
  width: 100%;
  margin-bottom: 70px;
  margin-top: 10px;
}

.carusel .css-1fzpoyk {
  cursor: pointer;
}

.section.sec-one .carusel {
  margin-bottom: 40px;
  height: 470px;
  width: 60%;
  position: absolute;
  right: 0;
  bottom: 90px;
}

.carusel .carusel-holder {
  width: 77%;
  height: 400px;
  margin: 0px auto;
}

span.carusellName {
  background: #141414;
  padding: 4px 10px 7px;
  position: absolute;
  bottom: -9%;
  left: 35%;
  border-radius: 9px;
}

.carusel .css-1fzpoyk:first-child span.carusellName, .carusel .css-1fzpoyk:last-child span.carusellName {
  opacity: 0 !important;
}

.next {
  position: absolute;
  top: 0px;
  text-indent: -900em;
  width: 100px;
  height: 320px;
  z-index: 100;
  cursor: pointer;
  right: 0px;
}

.prev {
  position: absolute;
  text-indent: -900em;
  width: 100px;
  z-index: 100;
  cursor: pointer;
  height: 320px;
}
.countdown-title {
    letter-spacing: 2px;
    text-align: center;
    text-transform: uppercase;
}

.countdown-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.countdown-item {
    color: white;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 30px;
    margin: 10px;
    padding-top: 10px;
    position: relative;
    width: 100px;
    height: 100px;
    font-family: 'Lato', sans-serif;
}

.countdown-item span {
    color: white;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}

.countdown-svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
}

.item-title {
    text-decoration: none !important;
}

@media only screen and (max-width: 481px) {
    .countdown-item {
        margin: 20px;
    }
}
.Home_sxl_home_content__2N4Ua {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(1vh * 100);
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  padding: 40px 0;
}

.Home_sxl_main_logo__2tJN6 {
  text-align: center;
  margin-bottom: 72px;
}

.Home_sxl_main_logo__2tJN6 img {
  display: block;
}

.Home_logo__1dCHP {
  width: 360px;
  height: 86px;
}

.Home_sxl_title_msg__2Q0VZ {
  text-align: center;
  margin-bottom: 42px;
  margin-top: 22px;
  color: white;
}

.Home_sxl_title_msg__2Q0VZ h1 {
  font-family: "Avenir-Black";
  font-size: 46px;
  line-height: 46px;
  color: white;
  margin-bottom: 10px;
}

.Home_sxl_title_msg__2Q0VZ h3 {
  font-family: "Avenir-Black";
  font-size: 20px;
  line-height: 36px;
  color: white;
}

.Home_sxl_title_msg__2Q0VZ p {
  font-family: "Avenir-Roman";
  font-size: 16px;
  line-height: 22px;
  color: white;
}

.Home_sxl_title_msg__2Q0VZ p span {
  text-decoration: underline;
  white-space: nowrap;
}

.Home_telemach_logo__1EYZc {
  height: 35px;
  margin-left: 6px;
}

.Home_sxl_vote_btn__1Ukst {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 84px;
}

.Home_sxl_vote_btn__1Ukst a {
  text-decoration: none;
  font-family: "Avenir-Black";
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  display: inline-block;
  max-width: 222px;
  width: 100%;
  height: 55px;
  min-width: 266px;
  border-radius: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d4ab40;
}

.Home_sxl_logos_holder__3-CjZ {
  margin-bottom: 40px;
}

.Home_sxl_logos__2_Bbs {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px;
  margin-bottom: 40px;
}

.Home_sxl_logos__2_Bbs:last-of-type {
  margin-bottom: 0;
}

.Home_sxl_logo_item__8rkAK {
  height: 40px;
}

.Home_sxl_pravila__1j0SD p {
  font-family: "Avenir-Roman";
  font-size: 14px;
  line-height: 19px;
  color: #000000;
}

.Home_sxl_pravila__1j0SD p span {
  text-decoration: underline;
}

.Home_sxl_pravila__1j0SD p a {
  color: #000000;
  white-space: nowrap;
}

@media (hover: hover) {
  .Home_sxl_vote_btn__1Ukst a:hover {
    opacity: 0.8;
  }
}


/*  MEDIA */
@media only screen and (max-width: 940px) {}

@media only screen and (max-width: 700px) {
  .Home_sxl_main_logo__2tJN6 {
    margin-bottom: 50px;
  }

  .Home_sxl_main_logo__2tJN6 img {
    width: auto;
  }

  .Home_sxl_title_msg__2Q0VZ {
    margin-bottom: 56px;
  }

  .Home_sxl_title_msg__2Q0VZ h1 {
    margin-bottom: 0px;
    font-size: 36px;
  }

  .Home_sxl_title_msg__2Q0VZ h3 {
    font-size: 18px;
  }

  .Home_telemach_logo__1EYZc {
    height: 30px;
  }

  .Home_sxl_logos_holder__3-CjZ {
    margin-bottom: 60px;
  }

  .Home_sxl_logos__2_Bbs {
    grid-gap: 28px;
    gap: 28px;
    margin-bottom: 34px;
    flex-wrap: wrap;
  }

  .Home_sxl_logos__2_Bbs:first-of-type img {
    height: 35px;
  }

  .Home_sxl_logos__2_Bbs:last-of-type img:nth-child(1),
  .Home_sxl_logos__2_Bbs:last-of-type img:nth-child(2) {
    height: 41px;
  }

  .Home_sxl_logos__2_Bbs:last-of-type img:nth-child(3) {
    height: 21px;
  }

  .Home_sxl_logos__2_Bbs:last-of-type img:nth-child(4) {
    height: 31px;
  }

  .Home_sxl_logos__2_Bbs:last-of-type img:nth-child(5) {
    height: 51px;
  }

  .Home_sxl_pravila__1j0SD {
    text-align: center;
  }
}

@media only screen and (max-width: 486px) {

  .Home_sxl_title_msg__2Q0VZ h1 {
    font-size: 26px;
    margin-bottom: -5px;
  }

  .Home_sxl_title_msg__2Q0VZ h3 {
    font-size: 14px;
  }

  .Home_telemach_logo__1EYZc {
    height: 20px;
  }

}
.SuccessfullySubmitted_sxl_home_content__CaPJq {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(1vh * 100);
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  padding: 40px 0;
}
.SuccessfullySubmitted_sxl_main_logo__1KcIf {
  text-align: center;
  margin-bottom: 72px;
}
.SuccessfullySubmitted_sxl_main_logo__1KcIf img {
  display: block;
}

.SuccessfullySubmitted_sxl_title_msg__2sjqc {
  text-align: center;
  margin-bottom: 42px;
}
.SuccessfullySubmitted_sxl_title_msg__2sjqc h1 {
  font-family: "Avenir-Black";
  font-size: 26px;
  line-height: 36px;
  color: #ffffff;
  margin-bottom: 20px;
}
.SuccessfullySubmitted_sxl_title_msg__2sjqc h1 span {
  color: #A38E60;
  text-decoration: underline;
}

.SuccessfullySubmitted_sxl_vote_btn__32Io- {
  width: 100%;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.SuccessfullySubmitted_sxl_vote_btn__32Io- div{
  text-decoration: none;
  font-family: "Avenir-Black";
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  display: inline-block;
  max-width: 222px;
  width: 100%;
  height: 46px;
  border-radius: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #A38E60;
}
.SuccessfullySubmitted_sxl_vote_btn__32Io- img {
  margin-right: 10px;
}
.SuccessfullySubmitted_sxl_logos_holder__2PPFZ{
  margin-bottom: 94px;
}
.SuccessfullySubmitted_sxl_logos__32vBG {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px;
  margin-bottom: 40px;
}
.SuccessfullySubmitted_sxl_logos__32vBG:last-of-type {
  margin-bottom: 0;
}

.SuccessfullySubmitted_sxl_logo_item__qjA5q {
  height: 40px;
}

.SuccessfullySubmitted_sxl_pravila__2QDnJ p{
  font-family: "Avenir-Roman";
  font-size: 14px;
  line-height: 19px;
  color: #FFFFFF;
}
.SuccessfullySubmitted_sxl_pravila__2QDnJ p span{
  text-decoration: underline;
}
.SuccessfullySubmitted_sxl_pravila__2QDnJ p a{ 
  color: #FFFFFF;
  white-space: nowrap;
}

@media (hover: hover) {
  .SuccessfullySubmitted_sxl_vote_btn__32Io- a:hover {
    opacity: 0.8;
  }
}


/*  MEDIA */
@media only screen and (max-width: 940px) {
}

@media only screen and (max-width: 700px) {
  .SuccessfullySubmitted_sxl_main_logo__1KcIf {
    margin-bottom: 50px;
  }
  .SuccessfullySubmitted_sxl_main_logo__1KcIf img {
    width: 188px;
  }
  .SuccessfullySubmitted_sxl_title_msg__2sjqc {
    margin-bottom: 56px;
  }
  .SuccessfullySubmitted_sxl_title_msg__2sjqc h1 {
    margin-bottom: 4px;
  }
  .SuccessfullySubmitted_sxl_logos_holder__2PPFZ{
    margin-bottom: 60px;
  }
  .SuccessfullySubmitted_sxl_logos__32vBG {
    grid-gap: 28px;
    gap: 28px;
    margin-bottom: 34px;
    flex-wrap: wrap;
  }
  .SuccessfullySubmitted_sxl_logos__32vBG:first-of-type img {
    height: 35px;
  }
  .SuccessfullySubmitted_sxl_logos__32vBG:last-of-type img:nth-child(1),
  .SuccessfullySubmitted_sxl_logos__32vBG:last-of-type img:nth-child(2) {
    height: 41px;
  }
  .SuccessfullySubmitted_sxl_logos__32vBG:last-of-type img:nth-child(3) {
    height: 21px;
  }
  .SuccessfullySubmitted_sxl_logos__32vBG:last-of-type img:nth-child(4) {
    height: 31px;
  }
  .SuccessfullySubmitted_sxl_logos__32vBG:last-of-type img:nth-child(5) {
    height: 51px;
  }
  .SuccessfullySubmitted_sxl_pravila__2QDnJ {
    text-align: center;
  }

  .SuccessfullySubmitted_sxl_vote_btn__32Io- {
    flex-direction: column;
    padding: 0 15px;
  }
  .SuccessfullySubmitted_sxl_vote_btn__32Io- a {
    display: flex;
    width: 100%;
    max-width: 100%;
  }
}
.Footer_sxl_footer__2cr7A {
  display: flex;
  justify-content: center;
}

.Footer_sxl_footer_content__3HC-a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Footer_sxl_logo_item__2Bha7 {
  height: 40px;
  min-width: 80px;
  max-width: 400px;
}

.Footer_sxl_pravila__kGCaH {
  margin-bottom: 30px;
}

.Footer_sxl_pravila__kGCaH p {
  font-family: "Avenir-Roman";
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
}

.Footer_sxl_pravila__kGCaH p span {
  text-decoration: underline;
}

.Footer_sxl_pravila__kGCaH p a {
  color: #FFFFFF;
}

.Footer_sxl_logos__AVTU9 {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px;
  margin-bottom: 40px;
}

.Footer_sxl_vote_btn__9GdOd {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 84px;
}

.Footer_sxl_vote_btn__9GdOd a {
  text-decoration: none;
  font-family: "Avenir-Black";
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  display: inline-block;
  max-width: 222px;
  width: 100%;
  height: 46px;
  border-radius: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #A38E60
}

@media (hover: hover) {}

@media only screen and (max-width: 940px) {}

@media only screen and (max-width: 767px) {
  .Footer_sxl_footer__2cr7A {
    padding: 0 15px;
  }

  .Footer_sxl_pravila__kGCaH {
    width: 100%;
    text-align: center;
  }

  .Footer_sxl_pravila__kGCaH a {
    white-space: nowrap;
  }

  .Footer_sxl_logos__AVTU9 {
    flex-wrap: wrap;
  }
  .Footer_sxl_logos__AVTU9:first-of-type img {
    height: 35px;
  }
}

/* HOVER */
@media (hover: hover) {
}

/*  MEDIA */
@media only screen and (max-width: 940px) {
}

@media only screen and (max-width: 700px) {
 
}

/* HOVER */
@media (hover: hover) {
}

/*  MEDIA */
@media only screen and (max-width: 940px) {
}

@media only screen and (max-width: 700px) {
 
}
.BestPlayersRightNow_sxl_title__FJeuQ {
  font-family: "Avenir-Black";
  font-size: 17px;
  line-height: 21px;
  height: 39px;
  display: table;
  padding: 9px 26px;
  text-align: center;
  z-index: 1;
  background-color: #fff;
  color: #141414;
  box-shadow: 0px 5px 11px #00000029;
  border-radius: 20px;
  margin: auto;
  margin-bottom: -10px;
}
.BestPlayersRightNow_sxl_best_11_content__2Aszg {
  display: flex;
  justify-content: center;
  height: 604px;
  max-width: 510px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 23px;
  overflow: hidden;
  margin: auto;
  margin-bottom: 42px;
}

.BestPlayersRightNow_sxl_best_11_players__3wIa7 {
  position: relative;
  max-width: 510px;
  width: 100%;
  padding: 40px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.BestPlayersRightNow_sxl_field_bg__1Xmau {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.BestPlayersRightNow_sxl_field_bg_small__1SI2O {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.BestPlayersRightNow_sxl_selected_players__15lTd {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.BestPlayersRightNow_sxl_selected_gk__1SNNP,
.BestPlayersRightNow_sxl_selected_def__3Rm5y,
.BestPlayersRightNow_sxl_selected_mid__1A7Yy,
.BestPlayersRightNow_sxl_selected_att__2Toip {
  display: flex;
  justify-content: center;
  grid-gap: 25px;
  gap: 25px;
  margin-bottom: 15px;
}

.BestPlayersRightNow_sxl_player_item__SL8Uk {
  flex-direction: column;
  align-items: center;
}
.BestPlayersRightNow_sxl_player_img__1qPAx {
  position: relative;
  width: 78px;
  height: 78px;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
  margin-bottom: 3px;
}
.BestPlayersRightNow_sxl_player_img__1qPAx img{
  position: absolute;
  top: 0;
  left: 0;
  width: 78px;
  height: 78px;
  object-fit: cover;
  object-position: center;
}
.BestPlayersRightNow_sxl_player_position__1Mj7T {
  color: #fff;
  padding: 3px 10px;
  font-family: "Avenir-Black";
  font-size: 13px;
  line-height: 19px;
  text-align: center;
}
.BestPlayersRightNow_sxl_player_last_name__3lV_4 {
  background: #141414;
  color: #fff;
  padding: 3px 10px;
  border-radius: 10px;
  font-family: "Avenir-Black";
  font-size: 13px;
  line-height: 19px;
  text-align: center;
}

/* select */
.BestPlayersRightNow_sxl_select_holder_sort__DvCoP {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1;
}
.BestPlayersRightNow_sxl_select_holder_sort__DvCoP select{
  width: 100%;
  height: 24px;
  border: 1px solid transparent;
  border-radius: 17px;
  background: #fff3;
  font-family: "Avenir-Roman";
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  outline: none;
  margin-bottom: 10px;
  cursor: pointer;
}
.BestPlayersRightNow_sxl_select_holder_sort__DvCoP select option{
  font-family: "Avenir-Roman";
  font-size: 16px;
  line-height: 22px;
  color: #141414;
}
.BestPlayersRightNow_sxl_select_holder_sort__DvCoP select option:first-of-type{
  display: none;
}

/* select period */
.BestPlayersRightNow_sxl_select_holder_period__2vJM8 {
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 1;
}
.BestPlayersRightNow_sxl_select_holder_period__2vJM8 select{
  width: 100%;
  height: 24px;
  border: 1px solid transparent;
  border-radius: 17px;
  background: #fff3;
  font-family: "Avenir-Roman";
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  outline: none;
  margin-bottom: 10px;
  cursor: pointer;
}
.BestPlayersRightNow_sxl_select_holder_period__2vJM8 select option{
  font-family: "Avenir-Roman";
  font-size: 16px;
  line-height: 22px;
  color: #141414;
}
.BestPlayersRightNow_sxl_select_holder_period__2vJM8 select option:first-of-type{
  display: none;
}

/*  MEDIA */
@media only screen and (max-width: 940px) {
  /* selected players left */
  .BestPlayersRightNow_sxl_best_11_players__3wIa7 {
    padding: 80px 15px 26px;
  }

  .BestPlayersRightNow_sxl_selected_gk__1SNNP,
  .BestPlayersRightNow_sxl_selected_def__3Rm5y,
  .BestPlayersRightNow_sxl_selected_mid__1A7Yy,
  .BestPlayersRightNow_sxl_selected_att__2Toip {
    grid-gap: 5px;
    gap: 5px;
  }

  .BestPlayersRightNow_sxl_player_img__1qPAx {
    width: 70px;
    height: 70px;
  }
  .BestPlayersRightNow_sxl_player_img__1qPAx img{
    width: 70px;
    height: 70px;
  }
  /* selected players left end */
  
}

@media only screen and (max-width: 767px) {
  .BestPlayersRightNow_sxl_title__FJeuQ {
    width: auto;
    position: absolute;
    left: 51%;
    transform: translateX(-50%);
    white-space: nowrap;
    top: 24px;
  }
  .BestPlayersRightNow_sxl_best_holder__1XR-R {
    position: relative;
  }
  .BestPlayersRightNow_sxl_best_11_content__2Aszg {
    border-radius: 0;
    min-height: 604px;
    height: auto;
    max-width: 100%;
  }
  .BestPlayersRightNow_sxl_best_11_players__3wIa7 {
    max-width: 100%;
  }
  .BestPlayersRightNow_sxl_field_bg__1Xmau {
    display: none;
  }
  .BestPlayersRightNow_sxl_field_bg_small__1SI2O {
    display: block;
  }

  .BestPlayersRightNow_sxl_select_holder_sort__DvCoP {
    top: 80px;
    right: 15px;
  }

  .BestPlayersRightNow_sxl_select_holder_period__2vJM8 {
    top: 80px;
    left: 15px;
  }
}
.BestPlayer_sxl_title__hG5Ta {
  font-family: "Avenir-Black";
  font-size: 17px;
  line-height: 21px;
  height: 39px;
  display: table;
  padding: 9px 26px;
  text-align: center;
  z-index: 1;
  background-color: #fff;
  color: #141414;
  box-shadow: 0px 5px 11px #00000029;
  border-radius: 20px;
  margin: auto;
  margin-bottom: -10px;
}

.BestPlayer_sxl_best_11_content__1gzk1 {
  display: flex;
  justify-content: center;
  height: 604px;
  max-width: 510px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 23px;
  overflow: hidden;
  margin: auto;
  margin-bottom: 42px;
}

.BestPlayer_sxl_best_11_players__31MbI {
  position: relative;
  max-width: 510px;
  width: 100%;
  padding: 40px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.BestPlayer_sxl_field_bg__2FYBZ {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.BestPlayer_sxl_field_bg_small__3pRF7 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.BestPlayer_sxl_selected_players__1lxBE {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1;
}

.BestPlayer_sxl_best_players__2r-aO {
  width: 100%;
  height: 261px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  grid-gap: 15px;
  gap: 15px;
}

.BestPlayer_sxl_player_item__2CI4N {
  flex-direction: column;
  align-items: center;
  align-self: flex-end;
}

.BestPlayer_sxl_player_img__2HWTp {
  position: relative;
  width: 78px;
  height: 78px;
  border-radius: 50%;
  margin: auto;
  margin-bottom: 3px;
}

.BestPlayer_sxl_player_img__2HWTp .BestPlayer_sxl_pl_img__2FJZ4 {
  position: absolute;
  top: 0;
  left: 0;
  width: 78px;
  height: 78px;
  object-fit: cover;
  object-position: center;
}

.BestPlayer_sxl_player_item__2CI4N .BestPlayer_sxl_player_img__2HWTp .BestPlayer_sxl_pl_img__2FJZ4 {
  width: 78px;
  height: 78px;
}

.BestPlayer_sxl_player_item__2CI4N .BestPlayer_sxl_player_img__2HWTp .BestPlayer_sxl_ribon__3qTGm {
  position: absolute;
  right: -4px;
  top: -10px;
  z-index: 0;
  width: 34px;
}

.BestPlayer_sxl_player_last_name__2-JRs {
  background: #141414;
  color: #fff;
  padding: 3px 10px;
  border-radius: 16px;
  font-family: "Avenir-Black";
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  display: table;
  margin: auto;
}

.BestPlayer_sxl_player_item__2CI4N:nth-child(2) {
  align-self: flex-start;
}

.BestPlayer_sxl_player_item__2CI4N:nth-child(2) .BestPlayer_sxl_player_img__2HWTp {
  width: 188px;
  height: 188px;
  margin-bottom: 9px;
}

.BestPlayer_sxl_player_item__2CI4N:nth-child(2) .BestPlayer_sxl_player_img__2HWTp .BestPlayer_sxl_pl_img__2FJZ4 {
  width: 188px;
  height: 188px;
  border: 4px solid #FEA929;
  border-radius: 50%;
  box-shadow: 0px 0px 90px #fea929;
}

.BestPlayer_sxl_player_item__2CI4N:nth-child(2) .BestPlayer_sxl_player_img__2HWTp .BestPlayer_sxl_ribon__3qTGm {
  position: absolute;
  right: 30px;
  top: -12px;
  z-index: 0;
  width: 38px;
}

.BestPlayer_sxl_player_item__2CI4N:nth-child(2) .BestPlayer_sxl_player_last_name__2-JRs {
  font-size: 16px;
  padding: 5px 12px;
}

/* select */
.BestPlayer_sxl_select_holder_sort__1T6H7 {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1;
}

.BestPlayer_sxl_select_holder_sort__1T6H7 select {
  width: 100%;
  height: 24px;
  border: 1px solid transparent;
  border-radius: 17px;
  background: #fff3;
  font-family: "Avenir-Roman";
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  outline: none;
  margin-bottom: 10px;
  cursor: pointer;
}

.BestPlayer_sxl_select_holder_sort__1T6H7 select option {
  font-family: "Avenir-Roman";
  font-size: 16px;
  line-height: 22px;
  color: #141414;
}

.BestPlayer_sxl_select_holder_sort__1T6H7 select option:first-of-type {
  display: none;
}

/* select period */
.BestPlayer_sxl_select_holder_period__11t33 {
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 1;
}

.BestPlayer_sxl_select_holder_period__11t33 select {
  width: 100%;
  height: 24px;
  border: 1px solid transparent;
  border-radius: 17px;
  background: #fff3;
  font-family: "Avenir-Roman";
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  outline: none;
  margin-bottom: 10px;
  cursor: pointer;
}

.BestPlayer_sxl_select_holder_period__11t33 select option {
  font-family: "Avenir-Roman";
  font-size: 16px;
  line-height: 22px;
  color: #141414;
}

.BestPlayer_sxl_select_holder_period__11t33 select option:first-of-type {
  display: none;
}

/*  MEDIA */
@media only screen and (max-width: 940px) {
  .BestPlayer_sxl_best_11_players__31MbI {
    padding: 80px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .BestPlayer_sxl_title__hG5Ta {
    width: auto;
    position: absolute;
    left: 51%;
    transform: translateX(-50%);
    white-space: nowrap;
    top: 24px;
  }

  .BestPlayer_sxl_best_holder__3gE75 {
    position: relative;
  }

  .BestPlayer_sxl_best_11_content__1gzk1 {
    border-radius: 0;
    min-height: 604px;
    height: auto;
    max-width: 100%;
  }

  .BestPlayer_sxl_best_11_players__31MbI {
    max-width: 100%;
  }

  .BestPlayer_sxl_field_bg__2FYBZ {
    display: none;
  }

  .BestPlayer_sxl_field_bg_small__3pRF7 {
    display: block;
  }

  .BestPlayer_sxl_best_players__2r-aO {
    flex-wrap: wrap;
  }

  .BestPlayer_sxl_player_item__2CI4N:nth-child(1) {
    order: 2;
    margin-right: 30px;
  }

  .BestPlayer_sxl_player_item__2CI4N:nth-child(2) {
    order: 1;
    width: 100%;
  }

  .BestPlayer_sxl_player_item__2CI4N:nth-child(3) {
    order: 3;
    margin-left: 30px;
  }

  .BestPlayer_sxl_select_holder_sort__1T6H7 {
    top: 80px;
    right: 15px;
  }

  .BestPlayer_sxl_select_holder_period__11t33 {
    top: 80px;
    left: 15px;
  }
}
.BestCoach_sxl_title__38GDx {
  font-family: "Avenir-Black";
  font-size: 17px;
  line-height: 21px;
  height: 39px;
  display: table;
  padding: 9px 26px;
  text-align: center;
  z-index: 1;
  background-color: #fff;
  color: #141414;
  box-shadow: 0px 5px 11px #00000029;
  border-radius: 20px;
  margin: auto;
  margin-bottom: -10px;
}

.BestCoach_sxl_best_coach_wrap__2gO4K {
  display: flex;
  justify-content: center;
  height: 604px;
  max-width: 510px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 23px;
  overflow: hidden;
  margin: auto;
  margin-bottom: 42px;
}

.BestCoach_sxl_best_coaches__12AVT {
  position: relative;
  max-width: 510px;
  width: 100%;
  padding: 40px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.BestCoach_sxl_field_bg__1pQkH {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.BestCoach_sxl_field_bg_small__3fmjR {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.BestCoach_sxl_selected_coach__2Ky6c {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1;
}

.BestCoach_sxl_best_coach__1S1v8 {
  width: 100%;
  height: 261px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  grid-gap: 15px;
  gap: 15px;
}

.BestCoach_sxl_coach_item__2dX25 {
  flex-direction: column;
  align-items: center;
  align-self: flex-end;
}

.BestCoach_sxl_coach_img__3iQpv {
  position: relative;
  width: 78px;
  height: 78px;
  border-radius: 50%;
  margin: auto;
  margin-bottom: 3px;
}

.BestCoach_sxl_coach_img__3iQpv .BestCoach_sxl_pl_img__3OKsH {
  position: absolute;
  top: 0;
  left: 0;
  width: 78px;
  height: 78px;
  object-fit: cover;
  object-position: center;
}

.BestCoach_sxl_coach_item__2dX25 .BestCoach_sxl_coach_img__3iQpv .BestCoach_sxl_pl_img__3OKsH {
  width: 78px;
  height: 78px;
}

.BestCoach_sxl_coach_item__2dX25 .BestCoach_sxl_coach_img__3iQpv .BestCoach_sxl_ribon__35zYz {
  position: absolute;
  right: -4px;
  top: -10px;
  z-index: 0;
  width: 34px;
}

.BestCoach_sxl_coach_last_name__2QDuA {
  background: #141414;
  color: #fff;
  padding: 3px 10px;
  border-radius: 16px;
  font-family: "Avenir-Black";
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  display: table;
  margin: auto;
}

.BestCoach_sxl_coach_item__2dX25:nth-child(2) {
  align-self: flex-start;
}

.BestCoach_sxl_coach_item__2dX25:nth-child(2) .BestCoach_sxl_coach_img__3iQpv {
  width: 188px;
  height: 188px;
  margin-bottom: 9px;
}

.BestCoach_sxl_coach_item__2dX25:nth-child(2) .BestCoach_sxl_coach_img__3iQpv .BestCoach_sxl_pl_img__3OKsH {
  width: 188px;
  height: 188px;
  border: 4px solid #FEA929;
  border-radius: 50%;
  box-shadow: 0px 0px 90px #fea929;
}

.BestCoach_sxl_coach_item__2dX25:nth-child(2) .BestCoach_sxl_coach_img__3iQpv .BestCoach_sxl_ribon__35zYz {
  position: absolute;
  right: 30px;
  top: -12px;
  z-index: 0;
  width: 38px;
}

.BestCoach_sxl_coach_item__2dX25:nth-child(2) .BestCoach_sxl_coach_last_name__2QDuA {
  font-size: 16px;
  padding: 5px 12px;
}

/* select */
.BestCoach_sxl_select_holder_sort__BlGaE {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1;
}

.BestCoach_sxl_select_holder_sort__BlGaE select {
  width: 100%;
  height: 24px;
  border: 1px solid transparent;
  border-radius: 17px;
  background: #fff3;
  font-family: "Avenir-Roman";
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  outline: none;
  margin-bottom: 10px;
  cursor: pointer;
}

.BestCoach_sxl_select_holder_sort__BlGaE select option {
  font-family: "Avenir-Roman";
  font-size: 16px;
  line-height: 22px;
  color: #141414;
}

.BestCoach_sxl_select_holder_sort__BlGaE select option:first-of-type {
  display: none;
}

/* select period */
.BestCoach_sxl_select_holder_period__2l1F9 {
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 1;
}

.BestCoach_sxl_select_holder_period__2l1F9 select {
  width: 100%;
  height: 24px;
  border: 1px solid transparent;
  border-radius: 17px;
  background: #fff3;
  font-family: "Avenir-Roman";
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  outline: none;
  margin-bottom: 10px;
  cursor: pointer;
}

.BestCoach_sxl_select_holder_period__2l1F9 select option {
  font-family: "Avenir-Roman";
  font-size: 16px;
  line-height: 22px;
  color: #141414;
}

.BestCoach_sxl_select_holder_period__2l1F9 select option:first-of-type {
  display: none;
}

/*  MEDIA */
@media only screen and (max-width: 940px) {
  .BestCoach_sxl_best_coaches__12AVT {
    padding: 80px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .BestCoach_sxl_title__38GDx {
    width: auto;
    position: absolute;
    left: 51%;
    transform: translateX(-50%);
    white-space: nowrap;
    top: 24px;
  }

  .BestCoach_sxl_best_holder__3fmjb {
    position: relative;
  }

  .BestCoach_sxl_best_coach_wrap__2gO4K {
    border-radius: 0;
    min-height: 604px;
    height: auto;
    max-width: 100%;
  }

  .BestCoach_sxl_best_coaches__12AVT {
    max-width: 100%;
  }

  .BestCoach_sxl_field_bg__1pQkH {
    display: none;
  }

  .BestCoach_sxl_field_bg_small__3fmjR {
    display: block;
  }

  .BestCoach_sxl_best_coach__1S1v8 {
    flex-wrap: wrap;
  }

  .BestCoach_sxl_coach_item__2dX25:nth-child(1) {
    order: 2;
    margin-right: 30px;
  }

  .BestCoach_sxl_coach_item__2dX25:nth-child(2) {
    order: 1;
    width: 100%;
  }

  .BestCoach_sxl_coach_item__2dX25:nth-child(3) {
    order: 3;
    margin-left: 30px;
  }

  .BestCoach_sxl_select_holder_sort__BlGaE {
    top: 80px;
    right: 15px;
  }

  .BestCoach_sxl_select_holder_period__2l1F9 {
    top: 80px;
    left: 15px;
  }
}
/* nav select players */
.Navigation_sxl_select_players_nav__4QJEp {
  padding: 40px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 46px;
}
.Navigation_sxl_main_logo__1UZtk {
  margin-right: 20px;
}
.Navigation_sxl_main_logo__1UZtk img {
  width: 128px;
  display: block;
}

.Navigation_sxl_nav__1ccmm {
  display: flex;
  align-items: center;
}
.Navigation_sxl_nav__1ccmm ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}
.Navigation_sxl_nav__1ccmm ul li{
  margin-right: 44px;
}
.Navigation_sxl_nav__1ccmm ul li:last-of-type{
  margin-right: 0;
}
.Navigation_sxl_nav__1ccmm ul li a{
  text-decoration: none; 
  color: #D2D4D6;
  font-family: "Avenir-Black";
  font-size: 16px;
  line-height: 22px;
  position: relative;
  padding-bottom: 4px;
}
.Navigation_sxl_nav__1ccmm ul li a.Navigation_active__fPOcU {
  color: white;
}
.Navigation_sxl_nav__1ccmm ul li a.Navigation_active__fPOcU:after {
  content: "";
  position: absolute;
  
  height: 4px;
  background-color: #A38E60;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
/* nav select players END */

@media only screen and (max-width: 767px) {
  .Navigation_sxl_select_players_nav__4QJEp {
    padding: 10px 0 0 0;
    margin-bottom: 0;
    flex-direction: column;
    align-items: center;
  }
  .Navigation_sxl_main_logo__1UZtk {
    margin-right: 0;
    margin-bottom: 30px;
  }
/* aaaaaaaaaaa */
  .Navigation_sxl_nav__1ccmm {
    width: 100%;
    border-bottom: 1px solid #E2E2E2;
  }
  .Navigation_sxl_nav__1ccmm ul{
    width: 100%;
  }
  .Navigation_sxl_nav__1ccmm ul li{
    margin-right: 0;
    flex: 1 1;
  }
  .Navigation_sxl_nav__1ccmm ul li a{
    display: block;
    text-align: center;
    border-bottom: 3px solid transparent;
    font-size: 14px;
    line-height: 19px;
    white-space: nowrap;
    justify-content: center;
    padding-bottom: 13px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .Navigation_sxl_nav__1ccmm ul li a.Navigation_active__fPOcU {
    color: white;
    border-bottom: 3px solid #A38E60;
  }
  .Navigation_sxl_nav__1ccmm ul li a.Navigation_active__fPOcU:after {
    height: 0;
  }

  /* aaaaaa */

  .Navigation_sxl_steps_holder__2ufVC {
    width: 100%;
    grid-gap: 0;
    gap: 0;
    border-bottom: 1px solid #E2E2E2;
  }
  .Navigation_sxl_step__1kkxL {
    flex: 1 1;
    grid-gap: 10px;
    gap: 10px;
    font-size: 14px;
    line-height: 19px;
    white-space: nowrap;
    justify-content: center;
    padding-bottom: 13px;
    padding-left: 8px;
    padding-right: 8px;
    border-bottom: 3px solid transparent;
  }
  .Navigation_sxl_step__1kkxL.Navigation_sxl_step_active__WWRAC{
    color: white;
    border-bottom: 3px solid #A38E60
  }
  .Navigation_sxl_step__1kkxL img {
    width: 18px;
  }
}
.SelectCoachField_sxl_select_coach_left__3NVCm {
  position: relative;
  width: 50%;
  padding: 40px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.SelectCoachField_sxl_field_bg__1VOI- {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.SelectCoachField_sxl_field_bg_small__37P0Z {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.SelectCoachField_sxl_select_coach__1-3Yt {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1;
}

.SelectCoachField_sxl_coach_item__2g-eW {
  flex-direction: column;
  align-items: center;
}
.SelectCoachField_sxl_coach_img__2vu4R {
  position: relative;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
  margin-bottom: 3px;
  cursor: pointer;
}
.SelectCoachField_sxl_coach_img__2vu4R img{
  position: absolute;
  top: 0;
  left: 0;
  width: 160px;
  height: 160px;
  object-fit: cover;
  object-position: center;
}
.SelectCoachField_sxl_coach_position__3VmDz {
  color: #fff;
  padding: 3px 10px;
  font-family: "Avenir-Black";
  font-size: 13px;
  line-height: 19px;
  text-align: center;
}
.SelectCoachField_sxl_coach_last_name__2UTzy {
  background: #141414;
  color: #fff;
  padding: 3px 10px;
  border-radius: 10px;
  font-family: "Avenir-Black";
  font-size: 13px;
  line-height: 19px;
  text-align: center;
}

.SelectCoachField_sxl_select_ctas__2nk78 {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 8px;
  gap: 8px;
}
.SelectCoachField_sxl_select_ctas__2nk78 a {
  text-decoration: none;
  display: block;
  max-width: 220px;
  width: 100%;
}
.SelectCoachField_sxl_select_cta_white__OvYSh,
.SelectCoachField_sxl_select_cta_white_small__suURi {
  max-width: 220px;
  width: 100%;
  height: 46px;
  border-radius: 23px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: "Avenir-Black";
  font-size: 16px;
  line-height: 22px;
  color: #141414;
  background-color: #fff;
  transition: all .3s ease;
}
.SelectCoachField_sxl_select_cta_black__pnaAt {
  max-width: 220px;
  width: 100%;
  height: 46px;
  border-radius: 23px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: "Avenir-Black";
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  background-color: #A38E60;
  transition: all .3s ease;
}
.SelectCoachField_sxl_select_cta_white_small__suURi {
  display: none;
}

/* HOVER */
@media (hover: hover) {
  .SelectCoachField_sxl_select_cta_white__OvYSh:hover,
  .SelectCoachField_sxl_select_cta_black__pnaAt:hover {
    opacity: 0.8;
  }
}

/*  MEDIA */
@media only screen and (max-width: 940px) {
  .SelectCoachField_sxl_select_coach_left__3NVCm {
    padding: 26px 15px;
  }
  .SelectCoachField_sxl_coach_img__2vu4R {
    width: 200px;
    height: 200px;
  }
  .SelectCoachField_sxl_coach_img__2vu4R img{
    width: 200px;
    height: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .SelectCoachField_sxl_select_coach_left__3NVCm {
    width: 100%;
  }
  .SelectCoachField_sxl_field_bg__1VOI- {
    display: none;
  }
  .SelectCoachField_sxl_field_bg_small__37P0Z {
    display: block;
  }
  .SelectCoachField_sxl_select_ctas__2nk78 {
    flex-direction: column;
  }
  .SelectCoachField_sxl_select_ctas__2nk78 a {
    max-width: 100%;
  }
  .SelectCoachField_sxl_select_cta_white__OvYSh,
  .SelectCoachField_sxl_select_cta_black__pnaAt,
  .SelectCoachField_sxl_select_cta_white_small__suURi {
    max-width: 100%;
  }
  .SelectCoachField_sxl_select_cta_white_small__suURi {
    display: flex;
  }
}
.SelectPlayersField_sxl_select_players_left__37ITe {
  position: relative;
  width: 50%;
  padding: 40px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.SelectPlayersField_sxl_field_bg__1Kc9R {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.SelectPlayersField_sxl_field_bg_small__2FMZR {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.SelectPlayersField_sxl_select_players__2oce7 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.SelectPlayersField_sxl_select_gk__3coIh,
.SelectPlayersField_sxl_select_def__ABC94,
.SelectPlayersField_sxl_select_mid__245Od,
.SelectPlayersField_sxl_select_att__Tq-6n {
  display: flex;
  justify-content: center;
  grid-gap: 25px;
  gap: 25px;
  margin-bottom: 15px;
}

.SelectPlayersField_sxl_player_item__1_izW {
  flex-direction: column;
  align-items: center;
}
.SelectPlayersField_sxl_player_img__eDAgo {
  position: relative;
  width: 78px;
  height: 78px;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
  margin-bottom: 3px;
  cursor: pointer;
}
.SelectPlayersField_sxl_player_img__eDAgo img{
  position: absolute;
  top: 0;
  left: 0;
  width: 78px;
  height: 78px;
  object-fit: cover;
  object-position: center;
}
.SelectPlayersField_sxl_player_position__DWfEr {
  color: #fff;
  padding: 3px 10px;
  font-family: "Avenir-Black";
  font-size: 13px;
  line-height: 19px;
  text-align: center;
}
.SelectPlayersField_sxl_player_last_name__3JeBj {
  background: #141414;
  color: #fff;
  padding: 3px 10px;
  border-radius: 10px;
  font-family: "Avenir-Black";
  font-size: 13px;
  line-height: 19px;
  text-align: center;
}

.SelectPlayersField_sxl_select_ctas__QhzDK {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 8px;
  gap: 8px;
}
.SelectPlayersField_sxl_select_ctas__QhzDK a {
  text-decoration: none;
  display: block;
  max-width: 220px;
  width: 100%;
}
.SelectPlayersField_sxl_select_cta_white__BigJ8,
.SelectPlayersField_sxl_select_cta_white_small__3aFCr {
  max-width: 220px;
  width: 100%;
  height: 46px;
  border-radius: 23px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: "Avenir-Black";
  font-size: 16px;
  line-height: 22px;
  color: #141414;
  background-color: #fff;
  text-align: center;
  transition: all .3s ease;
}
.SelectPlayersField_sxl_select_cta_black__2PN6j {
  max-width: 220px;
  width: 100%;
  height: 46px;
  border-radius: 23px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: "Avenir-Black";
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  background-color: #A38E60;
  text-align: center;
  transition: all .3s ease;
}
.SelectPlayersField_sxl_select_cta_white_small__3aFCr {
  display: none;
}

/* HOVER */
@media (hover: hover) {
  .SelectPlayersField_sxl_select_cta_white__BigJ8:hover,
  .SelectPlayersField_sxl_select_cta_black__2PN6j:hover {
    opacity: 0.8;
  }
}

/* MEDIA */
@media only screen and (max-width: 940px) {
  .SelectPlayersField_sxl_select_players_left__37ITe {
    padding: 26px 15px;
  }

  .SelectPlayersField_sxl_select_gk__3coIh,
  .SelectPlayersField_sxl_select_def__ABC94,
  .SelectPlayersField_sxl_select_mid__245Od,
  .SelectPlayersField_sxl_select_att__Tq-6n {
    grid-gap: 10px;
    gap: 10px;
  }

  .SelectPlayersField_sxl_player_img__eDAgo {
    width: 70px;
    height: 70px;
  }
  .SelectPlayersField_sxl_player_img__eDAgo img{
    width: 70px;
    height: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .SelectPlayersField_sxl_select_players_left__37ITe {
    width: 100%;
  }
  .SelectPlayersField_sxl_field_bg__1Kc9R {
    display: none;
  }
  .SelectPlayersField_sxl_field_bg_small__2FMZR {
    display: block;
  }
  .SelectPlayersField_sxl_select_ctas__QhzDK {
    flex-direction: column;
  }
  .SelectPlayersField_sxl_select_ctas__QhzDK a {
    max-width: 100%;
  }
  .SelectPlayersField_sxl_select_cta_white__BigJ8,
  .SelectPlayersField_sxl_select_cta_black__2PN6j,
  .SelectPlayersField_sxl_select_cta_white_small__3aFCr {
    max-width: 100%;
  }
  .SelectPlayersField_sxl_select_cta_white_small__3aFCr {
    display: flex;
  }
}


.SelectBestPlayerField_sxl_select_coach_left__2E_8P {
  position: relative;
  width: 50%;
  padding: 40px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.SelectBestPlayerField_sxl_field_bg__18rCR {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.SelectBestPlayerField_sxl_field_bg_small__2HseM {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.SelectBestPlayerField_sxl_select_coach__3I1yD {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1;
}

.SelectBestPlayerField_sxl_coach_item__3U-cz {
  flex-direction: column;
  align-items: center;
}

.SelectBestPlayerField_sxl_coach_img__17lDF {
  position: relative;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
  margin-bottom: 3px;
  cursor: pointer;
}

.SelectBestPlayerField_sxl_coach_img__17lDF img {
  position: absolute;
  top: 0;
  left: 0;
  width: 160px;
  height: 160px;
  object-fit: cover;
  object-position: center;
}

.SelectBestPlayerField_sxl_coach_position__FpgTT {
  color: #fff;
  padding: 3px 10px;
  font-family: "Avenir-Black";
  font-size: 13px;
  line-height: 19px;
  text-align: center;
}

.SelectBestPlayerField_sxl_coach_last_name__16L_P {
  background: #141414;
  color: #fff;
  padding: 3px 10px;
  border-radius: 10px;
  font-family: "Avenir-Black";
  font-size: 13px;
  line-height: 19px;
  text-align: center;
}

.SelectBestPlayerField_sxl_select_ctas__166LG {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 8px;
  gap: 8px;
}

.SelectBestPlayerField_sxl_select_ctas__166LG a {
  text-decoration: none;
  display: block;
  max-width: 220px;
  width: 100%;
}

.SelectBestPlayerField_sxl_select_cta_white__Dst2j,
.SelectBestPlayerField_sxl_select_cta_white_small__1PJg2 {
  max-width: 220px;
  width: 100%;
  height: 46px;
  border-radius: 23px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: "Avenir-Black";
  font-size: 16px;
  line-height: 22px;
  color: #141414;
  background-color: #fff;
  transition: all .3s ease;
}

.SelectBestPlayerField_sxl_select_cta_black__8hdZe {
  max-width: 220px;
  width: 100%;
  height: 46px;
  border-radius: 23px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: "Avenir-Black";
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  background-color: #A38E60;
  transition: all .3s ease;
}

.SelectBestPlayerField_sxl_select_cta_white_small__1PJg2 {
  display: none;
}

/* HOVER */
@media (hover: hover) {

  .SelectBestPlayerField_sxl_select_cta_white__Dst2j:hover,
  .SelectBestPlayerField_sxl_select_cta_black__8hdZe:hover {
    opacity: 0.8;
  }
}

/*  MEDIA */
@media only screen and (max-width: 940px) {
  .SelectBestPlayerField_sxl_select_coach_left__2E_8P {
    padding: 26px 15px;
  }

  .SelectBestPlayerField_sxl_coach_img__17lDF {
    width: 200px;
    height: 200px;
  }

  .SelectBestPlayerField_sxl_coach_img__17lDF img {
    width: 200px;
    height: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .SelectBestPlayerField_sxl_select_coach_left__2E_8P {
    width: 100%;
  }

  .SelectBestPlayerField_sxl_field_bg__18rCR {
    display: none;
  }

  .SelectBestPlayerField_sxl_field_bg_small__2HseM {
    display: block;
  }

  .SelectBestPlayerField_sxl_select_ctas__166LG {
    flex-direction: column;
  }

  .SelectBestPlayerField_sxl_select_ctas__166LG a {
    max-width: 100%;
  }

  .SelectBestPlayerField_sxl_select_cta_white__Dst2j,
  .SelectBestPlayerField_sxl_select_cta_black__8hdZe,
  .SelectBestPlayerField_sxl_select_cta_white_small__1PJg2 {
    max-width: 100%;
  }

  .SelectBestPlayerField_sxl_select_cta_white_small__1PJg2 {
    display: flex;
  }
}
.PlayerInfo_sxl_infos_right__3rSj2 {
  position: absolute;
  top: 0;
  left: 110%;
  width: 100%;
  height: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #141414;
  transition: all .3s ease;
  z-index: 99;
}

.PlayerInfo_sxl_infos_right__3rSj2.PlayerInfo_sxl_show_right__18BH9 {
  left: 0;
}

.PlayerInfo_sxl_infos_right_title__1bFba {
  font-family: "Avenir-Black";
  font-size: 23px;
  line-height: 29px;
  color: white;
  margin-bottom: 18px;
  align-items: center;
  justify-content: space-between;
  display: none;
}

.PlayerInfo_sxl_back_holder__2OKAj {
  display: none;
  align-items: center;
  justify-content: flex-start;
  font-family: "Avenir-Roman";
  font-size: 16px;
  line-height: 22px;
}

.PlayerInfo_sxl_back_holder__2OKAj img {
  margin-right: 9px;
  display: block;
  width: 18px;
}

.PlayerInfo_sxl_back_stats_icon__1sISF {
  display: none;
}

.PlayerInfo_sxl_main_info__2ZF2B {
  display: flex;
  margin-bottom: 20px;
}

.PlayerInfo_sxl_img_holder__3tStm {
  margin-right: 22px;
}

.PlayerInfo_sxl_img_holder__3tStm img {
  display: block;
  width: 124px;
  height: 124px;
  border-radius: 50%;
}

.PlayerInfo_sxl_info__2x8LG {
  flex: 1 1;
}

.PlayerInfo_sxl_name__3QmDU {
  font-family: "Avenir-Black";
  font-size: 28px;
  line-height: 36px;
  color: #ffffff;
  margin-bottom: 4px;
}

.PlayerInfo_sxl_info_position__1ZN6Y,
.PlayerInfo_sxl_index__1CvUx,
.PlayerInfo_sxl_team__2QqM7 {
  font-family: "Avenir-Roman";
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 6px;
  color: white
}

.PlayerInfo_sxl_index__1CvUx {
  display: flex;
  align-items: center;
}

.PlayerInfo_sxl_team__2QqM7 {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.PlayerInfo_sxl_team__2QqM7 img {
  width: 17px;
  margin-right: 4px;
}

.PlayerInfo_sxl_small_screen_xl_logo__3RMH6 {
  display: none;
}

.PlayerInfo_sxl_stats_title__1oPUX {
  font-family: "Avenir-Black";
  font-size: 23px;
  line-height: 29px;
  color: #ffffff;
  margin-bottom: 10px;
}

.PlayerInfo_sxl_stats_table__1P3S6 {
  flex: 1 1;
  overflow: hidden;
  overflow-y: auto;
}

/* custom scroll */
/* Works on Firefox */
.PlayerInfo_sxl_stats_table__1P3S6 {
  scrollbar-width: thin;
  scrollbar-color: #A38E60 white;
}

/* Works on Chrome, Edge, and Safari */
.PlayerInfo_sxl_stats_table__1P3S6::-webkit-scrollbar {
  width: 12px;
  margin-left: 10px;
  left: 10px;
}

.PlayerInfo_sxl_stats_table__1P3S6::-webkit-scrollbar-track {
  background: white;
}

.PlayerInfo_sxl_stats_table__1P3S6::-webkit-scrollbar-thumb {
  background-color: #A38E60;
  border-radius: 20px;
  border: 3px solid white;
}

.PlayerInfo_sxl_stats_table_item__awkEY {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 0;
  border-bottom: 1px solid #5c533b;
  padding-right: 30px;
}

.PlayerInfo_sxl_stats_table_key_bold__-BlGk {
  font-family: "Avenir-Black";
  font-size: 16px;
  color: #ffffff;
}

.PlayerInfo_sxl_stats_table_key__3tqRr {
  font-family: "Avenir-Roman";
  font-size: 16px;
  color: #ffffff;
}

.PlayerInfo_sxl_stats_table_value__3xwoS {
  font-family: "Avenir-Black";
  font-size: 16px;
  color: #ffffff;
}

.PlayerInfo_sxl_info_ctas_holder__L6ieg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.PlayerInfo_sxl_info_back__2PP2b {
  font-family: "Avenir-Book";
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 35px;
  color: white
}

.PlayerInfo_sxl_info_back__2PP2b img {
  margin-right: 8px;
}

.PlayerInfo_sxl_info_ctas__2XmH9 {
  display: flex;
}

.PlayerInfo_sxl_info_cta__atCmf {
  width: 190px;
  height: 46px;
  border-radius: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #A38E60;
  color: #FFFFFF;
  font-family: "Avenir-Black";
  font-size: 16px;
  line-height: 22px;
  padding: 0 8px;
  cursor: pointer;
  transition: all .3s ease;
}

.PlayerInfo_sxl_info_cta_remove__1XUT9 {
  width: 190px;
  height: 46px;
  border-radius: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FF2E63;
  color: #FFFFFF;
  font-family: "Avenir-Black";
  font-size: 16px;
  line-height: 22px;
  padding: 0 8px;
  cursor: pointer;
  transition: all .3s ease;
}


/*  MEDIA */
@media only screen and (max-width: 767px) {
  .PlayerInfo_sxl_infos_right__3rSj2 {
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 9;
    width: 100%;
    background-color: #141414;
    padding: 22px 15px 27px;
    min-height: auto;
    /* height: calc(var(--vh, 1vh) * 100); */
    overflow: auto;
    transition: all .3s ease;
  }

  .PlayerInfo_sxl_infos_right__3rSj2.PlayerInfo_sxl_show_right__18BH9 {
    left: 0;
  }

  .PlayerInfo_sxl_infos_right_title__1bFba {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 18px;
    display: flex;
  }

  .PlayerInfo_sxl_back_holder__2OKAj {
    display: flex;
    cursor: pointer;
  }

  .PlayerInfo_sxl_back_stats_icon__1sISF {
    display: block;
    width: 61px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
  }

  .PlayerInfo_sxl_small_add_vote_btn_holder__1nYV2 {
    display: block;
  }

  .PlayerInfo_sxl_main_info__2ZF2B {
    position: relative;
    padding: 32px 15px;
    border-radius: 12px;
    box-shadow: 0px 3px 6px #00000029;
  }

  .PlayerInfo_sxl_small_screen_xl_logo__3RMH6 {
    display: block;
    position: absolute;
    right: -5px;
    bottom: -20px;
    pointer-events: none;
    z-index: -1;
  }

  .PlayerInfo_sxl_info_ctas_holder__L6ieg {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    flex-direction: column;
  }

  .PlayerInfo_sxl_info_back__2PP2b {
    display: none;
  }

  .PlayerInfo_sxl_info_ctas__2XmH9 {
    width: 100%;
    flex-direction: column;
  }

  .PlayerInfo_sxl_info_cta__atCmf {
    width: 100%;
  }

  .PlayerInfo_sxl_info_cta_remove__1XUT9 {
    width: 100%;
  }
}
.CoachInfo_sxl_infos_right__2RhLF {
  position: absolute;
  top: 0;
  left: 110%;
  width: 100%;
  height: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  transition: all .3s ease;
  z-index: 99;
}
.CoachInfo_sxl_infos_right__2RhLF.CoachInfo_sxl_show_right__3cUVY {
  left: 0;
}
.CoachInfo_sxl_infos_right_title__2aKFo {
  font-family: "Avenir-Black";
  font-size: 23px;
  line-height: 29px;
  color: #141414;
  margin-bottom: 18px;
  align-items: center;
  justify-content: space-between;
  display: none;
}
.CoachInfo_sxl_back_holder__2-YhC{
  display: none;
  align-items: center;
  justify-content: flex-start;
  font-family: "Avenir-Roman";
  font-size: 16px;
  line-height: 22px;
}
.CoachInfo_sxl_back_holder__2-YhC img {
  margin-right: 9px;
  display: block;
  width: 18px;
}
.CoachInfo_sxl_back_stats_icon__22_Ho {
  display: none;
}

.CoachInfo_sxl_main_info__2hMp_ {
  display: flex;
  margin-bottom: 20px;
}
.CoachInfo_sxl_img_holder__11_Ax {
  margin-right: 22px;
}
.CoachInfo_sxl_img_holder__11_Ax img{
  display: block;
  width: 124px;
  height: 124px;
  border-radius: 50%;
}
.CoachInfo_sxl_info__1mq9A {
  flex: 1 1;
}
.CoachInfo_sxl_name__14Tr- {
  font-family: "Avenir-Black";
  font-size: 28px;
  line-height: 36px;
  color: #141414;
  margin-bottom: 4px;
}
.CoachInfo_sxl_info_position__18jhQ,
.CoachInfo_sxl_index__1l1IE,
.CoachInfo_sxl_team__150yC {
  font-family: "Avenir-Roman";
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 6px;
}
.CoachInfo_sxl_team__150yC {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.CoachInfo_sxl_team__150yC img {
  width: 17px;
  margin-right: 4px;
}
.CoachInfo_sxl_small_screen_xl_logo__1oza9 {
  display: none;
}

.CoachInfo_sxl_stats_title__2IgZi {
  font-family: "Avenir-Black";
  font-size: 23px;
  line-height: 29px;
  color: #141414;
  margin-bottom: 10px;
}

.CoachInfo_sxl_stats_table__3wfAM {
  flex: 1 1;
  overflow: hidden;
  overflow-y: auto;
}
/* custom scroll */
/* Works on Firefox */
.CoachInfo_sxl_stats_table__3wfAM {
  scrollbar-width: thin;
  scrollbar-color: #A38E60 white;
}

/* Works on Chrome, Edge, and Safari */
.CoachInfo_sxl_stats_table__3wfAM::-webkit-scrollbar {
  width: 12px;
  margin-left: 10px;
  left: 10px;
}

.CoachInfo_sxl_stats_table__3wfAM::-webkit-scrollbar-track {
  background: white;
}

.CoachInfo_sxl_stats_table__3wfAM::-webkit-scrollbar-thumb {
  background-color: #A38E60;
  border-radius: 20px;
  border: 3px solid white;
}

.CoachInfo_sxl_stats_table_item__3pQTC {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 0;
  border-bottom: 1px solid #E2E2E2;
}
.CoachInfo_sxl_stats_table_key_bold__1Iff_ {
  font-family: "Avenir-Black";
  font-size: 16px;
  color: #141414;
}
.CoachInfo_sxl_stats_table_key__3Uisi {
  font-family: "Avenir-Roman";
  font-size: 16px;
  color: #141414;
}
.CoachInfo_sxl_stats_table_value__31C-w {
  font-family: "Avenir-Black";
  font-size: 16px;
  color: #A38E60
}
.CoachInfo_sxl_info_ctas_holder__30Jej {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
}
.CoachInfo_sxl_info_back__10o3_ {
  font-family: "Avenir-Book";
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 35px;
}
.CoachInfo_sxl_info_back__10o3_ img {
  margin-right: 8px;
}
.CoachInfo_sxl_info_ctas__2MNnS {
  display: flex;
}
.CoachInfo_sxl_info_cta__1N6W3 {
  width: 190px;
  height: 46px;
  border-radius: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #A38E60;
  color: #FFFFFF;
  font-family: "Avenir-Black";
  font-size: 16px;
  line-height: 22px;
  padding: 0 8px;
  cursor: pointer;
  transition: all .3s ease;
}
.CoachInfo_sxl_info_cta_remove__1emSI {
  width: 190px;
  height: 46px;
  border-radius: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FF2E63;
  color: #FFFFFF;
  font-family: "Avenir-Black";
  font-size: 16px;
  line-height: 22px;
  padding: 0 8px;
  cursor: pointer;
  transition: all .3s ease;
}


/*  MEDIA */
@media only screen and (max-width: 767px) {
  .CoachInfo_sxl_infos_right__2RhLF {
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 9;
    width: 100%;
    background-color: #FFFFFF;
    padding: 22px 15px 27px;
    min-height: auto;
   
    overflow: auto;
    transition: all .3s ease;
  }
  .CoachInfo_sxl_infos_right__2RhLF.CoachInfo_sxl_show_right__3cUVY {
    left: 0;
  }
  .CoachInfo_sxl_infos_right_title__2aKFo {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 18px;
    display: flex;
  }
  .CoachInfo_sxl_back_holder__2-YhC{
    display: flex;
    cursor: pointer;
  }
  .CoachInfo_sxl_back_stats_icon__22_Ho {
    display: block;
    width: 61px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
  }
  .CoachInfo_sxl_small_add_vote_btn_holder__XgxrE {
    display: block;
  }

  .CoachInfo_sxl_main_info__2hMp_ {
    position: relative;
    padding: 32px 15px;
    border-radius: 12px;
    box-shadow: 0px 3px 6px #00000029;
  }
  .CoachInfo_sxl_small_screen_xl_logo__1oza9 {
    display: block;
    position: absolute;
    right: -5px;
    bottom: -20px;
    pointer-events: none;
    z-index: -1;
  }

  .CoachInfo_sxl_info_ctas_holder__30Jej {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    flex-direction: column;
  }
  .CoachInfo_sxl_info_back__10o3_ {
    display: none;
  }
  .CoachInfo_sxl_info_ctas__2MNnS {
    width: 100%;
    flex-direction: column;
  }
  .CoachInfo_sxl_info_cta__1N6W3 {
    width: 100%;
  }
  .CoachInfo_sxl_info_cta_remove__1emSI {
    width: 100%;
  }
}

/* HOVER */
@media (hover: hover) {
}

/*  MEDIA */
@media only screen and (max-width: 940px) {
}

@media only screen and (max-width: 700px) {
 
}

/* HOVER */
@media (hover: hover) {
}

/*  MEDIA */
@media only screen and (max-width: 940px) {
}

@media only screen and (max-width: 700px) {
 
}

/* HOVER */
@media (hover: hover) {
}

/*  MEDIA */
@media only screen and (max-width: 940px) {
}

@media only screen and (max-width: 700px) {
 
}

/* HOVER */
@media (hover: hover) {
}

/*  MEDIA */
@media only screen and (max-width: 940px) {
}

@media only screen and (max-width: 700px) {
 
}

/* HOVER */
@media (hover: hover) {
}

/*  MEDIA */
@media only screen and (max-width: 940px) {
}

@media only screen and (max-width: 700px) {
 
}
.PlayersList_sxl_select_players_right__1WM7x {
  position: relative;
  width: 50%;
  padding: 22px 18px 18px;
  display: flex;
  flex-direction: column;
  background-color: #141414;
}

.PlayersList_sxl_select_players_right_title__SH9FO {
  font-family: "Avenir-Black";
  font-size: 23px;
  line-height: 29px;
  color: #ffffff;
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.PlayersList_sxl_back_holder__1W7JB {
  display: none;
  align-items: center;
  justify-content: flex-start;
  font-family: "Avenir-Roman";
  font-size: 16px;
  line-height: 22px;
}

.PlayersList_sxl_back_holder__1W7JB img {
  margin-right: 9px;
  display: block;
  width: 18px;
}

.PlayersList_sxl_back_stats_icon__275DM {
  display: none;
}

.PlayersList_sxl_select_dropdowns__xKL3Q {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.PlayersList_sxl_select_search__1J_nc {
  position: relative;
  margin-bottom: 15px;
}

.PlayersList_sxl_select_allplayers_sort__1QmwE {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.PlayersList_sxl_select_allplayers__3xxCZ {
  font-family: "Avenir-Black";
  font-size: 18px;
  line-height: 29px;
  color: #ffffff;
  margin-bottom: 10px;
}

.PlayersList_sxl_select_players_table__P9PN0 {
  flex: 1 1;
  overflow: hidden;
  overflow-y: auto;
  padding-right: 15px;
}

/* custom scroll */
/* Works on Firefox */
.PlayersList_sxl_select_players_table__P9PN0 {
  scrollbar-width: thin;
  scrollbar-color: #A38E60 white;
}

/* Works on Chrome, Edge, and Safari */
.PlayersList_sxl_select_players_table__P9PN0::-webkit-scrollbar {
  width: 12px;
}

.PlayersList_sxl_select_players_table__P9PN0::-webkit-scrollbar-track {
  background: white;
}

.PlayersList_sxl_select_players_table__P9PN0::-webkit-scrollbar-thumb {
  background-color: #A38E60;
  border-radius: 20px;
  border: 3px solid white;
}

.PlayersList_sxl_small_add_vote_btn_holder__3SK3n {
  display: none;
  margin-top: 20px;
  width: 100%;
}

.PlayersList_sxl_small_add_vote_btn__1drSO {
  text-decoration: none;
  font-family: "Avenir-Black";
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  display: inline-block;
  max-width: 100%;
  width: 100%;
  height: 46px;
  border-radius: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #A38E60
}

/* select-s */
.PlayersList_sxl_select_dropdowns__xKL3Q {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
}

.PlayersList_sxl_select_holder__1PZ-m {
  width: 100%;
}

.PlayersList_sxl_select_holder__1PZ-m select {
  width: 100%;
  height: 40px;
  border-radius: 18px;
  border: 1px solid #333131;
  background: #fff;
  font-family: "Avenir-Roman";
  font-size: 16px;
  line-height: 22px;
  color: #141414;
  padding-left: 15px;
  padding-right: 15px;
  outline: none;
  cursor: pointer;
}

.PlayersList_sxl_select_holder__1PZ-m select:focus,
.PlayersList_sxl_select_holder__1PZ-m select:active {
  border: 1px solid #141414
}

.PlayersList_sxl_select_holder__1PZ-m select option {
  font-family: "Avenir-Roman";
  font-size: 16px;
  line-height: 22px;
  color: #141414;
}

.PlayersList_sxl_select_holder_sort__29_qw {
  margin-bottom: 10px;
}

.PlayersList_sxl_select_holder_sort__29_qw select {
  width: 100%;
  height: 24px;
  border: 1px solid transparent;
  background: #fff;
  font-family: "Avenir-Roman";
  font-size: 16px;
  line-height: 22px;
  color: #141414;
  outline: none;
  margin-bottom: 10px;
  cursor: pointer;
}

.PlayersList_sxl_select_holder_sort__29_qw select option {
  font-family: "Avenir-Roman";
  font-size: 16px;
  line-height: 22px;
  color: #141414;
}

.PlayersList_sxl_select_holder_sort__29_qw select option:first-of-type {
  display: none;
}

.PlayersList_params_text_carousel__1eOST {
  color: #FFFFFF;
  white-space: nowrap;
  font-size: 16px;
  line-height: 21px;
  font-family: Avenir-Book;
  margin-right: 30px;
}

/*  MEDIA */
@media only screen and (max-width: 767px) {
  .PlayersList_sxl_select_players_right__1WM7x {
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 9;
    width: 100%;
    height: 100% !important;
    background-color: #141414;
    padding: 22px 15px 27px;
    min-height: auto;
    height: calc(1vh * 100);
    height: calc(var(--vh, 1vh) * 100);
    overflow: auto;
    transition: all .3s ease;
  }

  .PlayersList_sxl_select_players_right__1WM7x.PlayersList_sxl_show_right__cMhwC {
    left: 0;
  }

  .PlayersList_sxl_select_players_right_title__SH9FO {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 18px;
  }

  .PlayersList_sxl_back_holder__1W7JB {
    display: flex;
    cursor: pointer;
  }

  .PlayersList_sxl_back_stats_icon__275DM {
    display: block;
    width: 61px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
  }

  .PlayersList_sxl_small_add_vote_btn_holder__3SK3n {
    display: block;
  }
}
/* nav select coach */
.Vote_sxl_select_players_nav__2ILF7 {
  padding: 40px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 46px;
}
.Vote_sxl_main_logo__2AQI5 {
  margin-right: 20px;
}
.Vote_sxl_main_logo__2AQI5 img {
  width: 128px;
  display: block;
}

.Vote_sxl_steps_holder__IZcFc {
  display: flex;
  align-items: center;
  grid-gap: 70px;
  gap: 70px;
}
.Vote_sxl_step__3P_7e {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  font-family: "Avenir-Black";
  font-size: 16px;
  line-height: 22px;
  color: #D2D4D6;
}
.Vote_sxl_step__3P_7e.Vote_sxl_step_active__U_wzN{
  color: #141414;
}
/* nav select coach END */

/* select coach */
.Vote_sxl_select_coach_content__rBEkB {
  display: flex;
  justify-content: space-between;
  height: 620px;
  width: 100%;
  background-color: rgb(0, 0, 0);
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 23px;
  overflow: hidden;
  margin-bottom: 42px;
}
/* select coach END */

/*  MEDIA */
@media only screen and (max-width: 767px) {
  /* nav select coach */
  .Vote_sxl_select_players_nav__2ILF7 {
    padding: 10px 0 0 0;
    margin-bottom: 0;
    flex-direction: column;
    align-items: center;
  }
  .Vote_sxl_main_logo__2AQI5 {
    margin-right: 0;
    margin-bottom: 30px;
  }

  .Vote_sxl_steps_holder__IZcFc {
    width: 100%;
    grid-gap: 0;
    gap: 0;
    border-bottom: 1px solid #E2E2E2;
  }
  .Vote_sxl_step__3P_7e {
    flex: 1 1;
    grid-gap: 10px;
    gap: 10px;
    font-size: 13px;
    line-height: 19px;
    white-space: nowrap;
    justify-content: center;
    padding-bottom: 13px;
    padding-left: 8px;
    padding-right: 8px;
    border-bottom: 3px solid transparent;
  }
  .Vote_sxl_step__3P_7e.Vote_sxl_step_active__U_wzN{
    color: #141414;
    border-bottom: 3px solid #A38E60;
  }
  .Vote_sxl_step__3P_7e img {
    width: 18px;
  }
  /* nav select coach END */

  /* select coach */
  .Vote_sxl_select_coach_content__rBEkB {
    border-radius: 0;
    min-height: 620px;
    height: auto;
  }
}
