.sxl_select_players_right {
  position: relative;
  width: 50%;
  padding: 22px 18px 18px;
  display: flex;
  flex-direction: column;
  background-color: #141414;
}

.sxl_select_players_right_title {
  font-family: "Avenir-Black";
  font-size: 23px;
  line-height: 29px;
  color: #ffffff;
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sxl_back_holder {
  display: none;
  align-items: center;
  justify-content: flex-start;
  font-family: "Avenir-Roman";
  font-size: 16px;
  line-height: 22px;
}

.sxl_back_holder img {
  margin-right: 9px;
  display: block;
  width: 18px;
}

.sxl_back_stats_icon {
  display: none;
}

.sxl_select_dropdowns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.sxl_select_search {
  position: relative;
  margin-bottom: 15px;
}

.sxl_select_allplayers_sort {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sxl_select_allplayers {
  font-family: "Avenir-Black";
  font-size: 18px;
  line-height: 29px;
  color: #ffffff;
  margin-bottom: 10px;
}

.sxl_select_players_table {
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  padding-right: 15px;
}

/* custom scroll */
/* Works on Firefox */
.sxl_select_players_table {
  scrollbar-width: thin;
  scrollbar-color: #A38E60 white;
}

/* Works on Chrome, Edge, and Safari */
.sxl_select_players_table::-webkit-scrollbar {
  width: 12px;
}

.sxl_select_players_table::-webkit-scrollbar-track {
  background: white;
}

.sxl_select_players_table::-webkit-scrollbar-thumb {
  background-color: #A38E60;
  border-radius: 20px;
  border: 3px solid white;
}

.sxl_small_add_vote_btn_holder {
  display: none;
  margin-top: 20px;
  width: 100%;
}

.sxl_small_add_vote_btn {
  text-decoration: none;
  font-family: "Avenir-Black";
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  display: inline-block;
  max-width: 100%;
  width: 100%;
  height: 46px;
  border-radius: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #A38E60
}

/* select-s */
.sxl_select_dropdowns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.sxl_select_holder {
  width: 100%;
}

.sxl_select_holder select {
  width: 100%;
  height: 40px;
  border-radius: 18px;
  border: 1px solid #333131;
  background: #fff;
  font-family: "Avenir-Roman";
  font-size: 16px;
  line-height: 22px;
  color: #141414;
  padding-left: 15px;
  padding-right: 15px;
  outline: none;
  cursor: pointer;
}

.sxl_select_holder select:focus,
.sxl_select_holder select:active {
  border: 1px solid #141414
}

.sxl_select_holder select option {
  font-family: "Avenir-Roman";
  font-size: 16px;
  line-height: 22px;
  color: #141414;
}

.sxl_select_holder_sort {
  margin-bottom: 10px;
}

.sxl_select_holder_sort select {
  width: 100%;
  height: 24px;
  border: 1px solid transparent;
  background: #fff;
  font-family: "Avenir-Roman";
  font-size: 16px;
  line-height: 22px;
  color: #141414;
  outline: none;
  margin-bottom: 10px;
  cursor: pointer;
}

.sxl_select_holder_sort select option {
  font-family: "Avenir-Roman";
  font-size: 16px;
  line-height: 22px;
  color: #141414;
}

.sxl_select_holder_sort select option:first-of-type {
  display: none;
}

.params_text_carousel {
  color: #FFFFFF;
  white-space: nowrap;
  font-size: 16px;
  line-height: 21px;
  font-family: Avenir-Book;
  margin-right: 30px;
}

/*  MEDIA */
@media only screen and (max-width: 767px) {
  .sxl_select_players_right {
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 9;
    width: 100%;
    height: 100% !important;
    background-color: #141414;
    padding: 22px 15px 27px;
    min-height: auto;
    height: calc(var(--vh, 1vh) * 100);
    overflow: auto;
    transition: all .3s ease;
  }

  .sxl_select_players_right.sxl_show_right {
    left: 0;
  }

  .sxl_select_players_right_title {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 18px;
  }

  .sxl_back_holder {
    display: flex;
    cursor: pointer;
  }

  .sxl_back_stats_icon {
    display: block;
    width: 61px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
  }

  .sxl_small_add_vote_btn_holder {
    display: block;
  }
}