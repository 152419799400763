@import url('https://fonts.googleapis.com/css?family=Lato');

.countdown-title {
    letter-spacing: 2px;
    text-align: center;
    text-transform: uppercase;
}

.countdown-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.countdown-item {
    color: white;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 30px;
    margin: 10px;
    padding-top: 10px;
    position: relative;
    width: 100px;
    height: 100px;
    font-family: 'Lato', sans-serif;
}

.countdown-item span {
    color: white;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}

.countdown-svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
}

.item-title {
    text-decoration: none !important;
}

@media only screen and (max-width: 481px) {
    .countdown-item {
        margin: 20px;
    }
}