/* FONTS */
@font-face {
  font-family: "Avenir-Black";
  src: local("Avenir-Black"), url("./fonts/Avenir-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir-Book";
  src: local("Avenir-Book"), url("./fonts/Avenir-Book.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir-Roman";
  src: local("Avenir-Roman"), url("./fonts/Avenir-Roman.ttf") format("truetype");
}

* {
  box-sizing: border-box;
  position: relative;
  transition: all 0.3s ease;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000
}

main {
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.sxl_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #141414;
}


.sxl_wrapper_home {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #141414;
}

.sxl_container {
  max-width: 991px;
  width: 100%;
  padding: 0 15px;
}

/* table */
.sxl_table {
  width: 100% !important;
  border-collapse: collapse;
}

.sxl_table th {
  font-family: "Avenir-Roman";
  font-size: 14px;
  color: #AFAFAF;
  font-weight: 400;
}

.sxl_table_name,
.sxl_table_value,
.sxl_table_team {
  font-family: "Avenir-Roman";
  font-size: 16px;
  color: #ffffff;
  padding-bottom: 14px;
  padding-top: 14px;
}

.sxl_table_name {
  text-decoration: underline;
  cursor: pointer;
}

.sxl_table_team {
  display: flex;
  align-items: center;
}

.sxl_table_team img {
  margin-right: 6px;
  max-width: 22px;
  max-height: 22px;
}

.sxl_table_border_bottom {
  border-bottom: 1px solid #5c533b
}

.sxl_table_border_bottom:last-of-type {
  border-bottom: 1px solid transparent
}

.sxl_row_checked {
  background-image: linear-gradient(to left, #a38e602f, transparent);
}

/* INPUT SEARCH */
.sxl_input_holder {
  position: relative;
  width: 100%;
}

.sxl_input_holder input {
  padding: 8px 8px 8px 40px;
  width: 100%;
  border-radius: 40px;
  background-color: #54585c69;
  border: 1px solid transparent;
  color: white;
  font-family: "Avenir-Roman";
  font-size: 16px;
  line-height: 22px;
  outline: none;
  transition: all .3s ease;
}

.sxl_input_holder input:focus,
.sxl_input_holder input:active {
  border: 1px solid #141414;
}

.sxl_input_holder img {
  position: absolute;
  left: 13px;
  top: 50%;
  transform: translateY(-50%);
  width: 17px;
  cursor: pointer;
}

/* INPUT SEARCH END */

/* CUSTOM CHECKBOX */
/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  /* overflow: hidden; */
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 1px solid #C3C3C3;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
/* .container:hover input ~ .checkmark {
  background-color: #A38E60
  border: 1px solid #A38E60 
} */

/* When the checkbox is checked, add a green background 
.container input:checked~.checkmark {
  background-color: #A38E60
  border: 1px solid #A38E60
} */
/*
/* Create the checkmark/indicator (hidden when not checked) *
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
} */

/* Show the checkmark when checked 
.container input:checked~.checkmark:after {
  display: block;
}  */

.checkedMark {
  background-color: #A38E60;
  border: 1px solid #A38E60;
  content: "";
  position: absolute;
  display: block;
}

.checkedArrow {
  position: absolute;
  left: 9px;
  top: 4px;
  width: 8px;
  height: 14px;
  border: solid rgb(255, 255, 255);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Style the checkmark/indicator 
.container .checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}*/

/* CUSTOM CHECKBOX END */

/* SIMPLEBAR STYLE */
.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  background: #A38E60
}

.simplebar-track.simplebar-vertical {
  right: -10px;
}

/* SIMPLEBAR STYLE END */

/* Alert button */

.alertButton {
  background-color: #A38E60;
  color: white;
  font-size: 10pt;
  padding: 5px 20px;
  border-radius: 5px;
  margin-right: 25px;
}

.buttonsHolder {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: center;
}

.submitted_vote {
  background-color: white;
  border-radius: 15px;
  width: 350px;
  text-align: center;
  height: 150px;
  padding-top: 35px;
  box-shadow: 0 5px 10px #acacac;
}

/* ContainerStyle */
.containerStyle {
  width: 100vw;
  margin: auto;
  height: 18px;
  background-color: red;
  text-align: center;
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
}

/* Spinner */

.spinerClass {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(70, 180, 85, 0.178);
  width: 100vw;
  height: 100vh;
  z-index: 101;
}

/* NOT FOUND */
.notFound {
  position: center;
  display: flex;
  text-align: center;
  flex-direction: column;
}

/* MEDIA */
@media only screen and (max-width: 767px) {
  .sxl_container {
    padding: 0;
  }
}

/* RULES MODAL */
.modalDisclamer {
  background-color: #141414;
  border-radius: 10px;
  color: #5c5c5c;
  margin: auto;
  overflow: auto;
  max-height: 90vh;
  width: 75%;
  margin-top: 50px;
  outline: none;
}

.modalDisclamer>div {
  padding: 10px;
}

.disclaimer {
  text-align: center;
  margin-top: 20px;
  clear: both;
  float: left;
  width: 100%;
}

.disclaimer a {
  color: #b8e080;
  cursor: pointer;
}

.ReactModal__Overlay {
  z-index: 100 !important;
}

/* Carousel */

.carusel {
  width: 100%;
  margin-bottom: 70px;
  margin-top: 10px;
}

.carusel .css-1fzpoyk {
  cursor: pointer;
}

.section.sec-one .carusel {
  margin-bottom: 40px;
  height: 470px;
  width: 60%;
  position: absolute;
  right: 0;
  bottom: 90px;
}

.carusel .carusel-holder {
  width: 77%;
  height: 400px;
  margin: 0px auto;
}

span.carusellName {
  background: #141414;
  padding: 4px 10px 7px;
  position: absolute;
  bottom: -9%;
  left: 35%;
  border-radius: 9px;
}

.carusel .css-1fzpoyk:first-child span.carusellName, .carusel .css-1fzpoyk:last-child span.carusellName {
  opacity: 0 !important;
}

.next {
  position: absolute;
  top: 0px;
  text-indent: -900em;
  width: 100px;
  height: 320px;
  z-index: 100;
  cursor: pointer;
  right: 0px;
}

.prev {
  position: absolute;
  text-indent: -900em;
  width: 100px;
  z-index: 100;
  cursor: pointer;
  height: 320px;
}