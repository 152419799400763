/* nav select coach */
.sxl_select_players_nav {
  padding: 40px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 46px;
}
.sxl_main_logo {
  margin-right: 20px;
}
.sxl_main_logo img {
  width: 128px;
  display: block;
}

.sxl_steps_holder {
  display: flex;
  align-items: center;
  gap: 70px;
}
.sxl_step {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: "Avenir-Black";
  font-size: 16px;
  line-height: 22px;
  color: #D2D4D6;
}
.sxl_step.sxl_step_active{
  color: #141414;
}
/* nav select coach END */

/* select coach */
.sxl_select_coach_content {
  display: flex;
  justify-content: space-between;
  height: 620px;
  width: 100%;
  background-color: rgb(0, 0, 0);
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 23px;
  overflow: hidden;
  margin-bottom: 42px;
}
/* select coach END */

/*  MEDIA */
@media only screen and (max-width: 767px) {
  /* nav select coach */
  .sxl_select_players_nav {
    padding: 10px 0 0 0;
    margin-bottom: 0;
    flex-direction: column;
    align-items: center;
  }
  .sxl_main_logo {
    margin-right: 0;
    margin-bottom: 30px;
  }

  .sxl_steps_holder {
    width: 100%;
    gap: 0;
    border-bottom: 1px solid #E2E2E2;
  }
  .sxl_step {
    flex: 1;
    gap: 10px;
    font-size: 13px;
    line-height: 19px;
    white-space: nowrap;
    justify-content: center;
    padding-bottom: 13px;
    padding-left: 8px;
    padding-right: 8px;
    border-bottom: 3px solid transparent;
  }
  .sxl_step.sxl_step_active{
    color: #141414;
    border-bottom: 3px solid #A38E60;
  }
  .sxl_step img {
    width: 18px;
  }
  /* nav select coach END */

  /* select coach */
  .sxl_select_coach_content {
    border-radius: 0;
    min-height: 620px;
    height: auto;
  }
}