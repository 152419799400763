/* nav select players */
.sxl_select_players_nav {
  padding: 40px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 46px;
}
.sxl_main_logo {
  margin-right: 20px;
}
.sxl_main_logo img {
  width: 128px;
  display: block;
}

.sxl_nav {
  display: flex;
  align-items: center;
}
.sxl_nav ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}
.sxl_nav ul li{
  margin-right: 44px;
}
.sxl_nav ul li:last-of-type{
  margin-right: 0;
}
.sxl_nav ul li a{
  text-decoration: none; 
  color: #D2D4D6;
  font-family: "Avenir-Black";
  font-size: 16px;
  line-height: 22px;
  position: relative;
  padding-bottom: 4px;
}
.sxl_nav ul li a.active {
  color: white;
}
.sxl_nav ul li a.active:after {
  content: "";
  position: absolute;
  
  height: 4px;
  background-color: #A38E60;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
/* nav select players END */

@media only screen and (max-width: 767px) {
  .sxl_select_players_nav {
    padding: 10px 0 0 0;
    margin-bottom: 0;
    flex-direction: column;
    align-items: center;
  }
  .sxl_main_logo {
    margin-right: 0;
    margin-bottom: 30px;
  }
/* aaaaaaaaaaa */
  .sxl_nav {
    width: 100%;
    border-bottom: 1px solid #E2E2E2;
  }
  .sxl_nav ul{
    width: 100%;
  }
  .sxl_nav ul li{
    margin-right: 0;
    flex: 1;
  }
  .sxl_nav ul li a{
    display: block;
    text-align: center;
    border-bottom: 3px solid transparent;
    font-size: 14px;
    line-height: 19px;
    white-space: nowrap;
    justify-content: center;
    padding-bottom: 13px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .sxl_nav ul li a.active {
    color: white;
    border-bottom: 3px solid #A38E60;
  }
  .sxl_nav ul li a.active:after {
    height: 0;
  }

  /* aaaaaa */

  .sxl_steps_holder {
    width: 100%;
    gap: 0;
    border-bottom: 1px solid #E2E2E2;
  }
  .sxl_step {
    flex: 1;
    gap: 10px;
    font-size: 14px;
    line-height: 19px;
    white-space: nowrap;
    justify-content: center;
    padding-bottom: 13px;
    padding-left: 8px;
    padding-right: 8px;
    border-bottom: 3px solid transparent;
  }
  .sxl_step.sxl_step_active{
    color: white;
    border-bottom: 3px solid #A38E60
  }
  .sxl_step img {
    width: 18px;
  }
}