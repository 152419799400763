.sxl_select_coach_left {
  position: relative;
  width: 50%;
  padding: 40px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sxl_field_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.sxl_field_bg_small {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.sxl_select_coach {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.sxl_coach_item {
  flex-direction: column;
  align-items: center;
}
.sxl_coach_img {
  position: relative;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
  margin-bottom: 3px;
  cursor: pointer;
}
.sxl_coach_img img{
  position: absolute;
  top: 0;
  left: 0;
  width: 160px;
  height: 160px;
  object-fit: cover;
  object-position: center;
}
.sxl_coach_position {
  color: #fff;
  padding: 3px 10px;
  font-family: "Avenir-Black";
  font-size: 13px;
  line-height: 19px;
  text-align: center;
}
.sxl_coach_last_name {
  background: #141414;
  color: #fff;
  padding: 3px 10px;
  border-radius: 10px;
  font-family: "Avenir-Black";
  font-size: 13px;
  line-height: 19px;
  text-align: center;
}

.sxl_select_ctas {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.sxl_select_ctas a {
  text-decoration: none;
  display: block;
  max-width: 220px;
  width: 100%;
}
.sxl_select_cta_white,
.sxl_select_cta_white_small {
  max-width: 220px;
  width: 100%;
  height: 46px;
  border-radius: 23px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: "Avenir-Black";
  font-size: 16px;
  line-height: 22px;
  color: #141414;
  background-color: #fff;
  transition: all .3s ease;
}
.sxl_select_cta_black {
  max-width: 220px;
  width: 100%;
  height: 46px;
  border-radius: 23px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: "Avenir-Black";
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  background-color: #A38E60;
  transition: all .3s ease;
}
.sxl_select_cta_white_small {
  display: none;
}

/* HOVER */
@media (hover: hover) {
  .sxl_select_cta_white:hover,
  .sxl_select_cta_black:hover {
    opacity: 0.8;
  }
}

/*  MEDIA */
@media only screen and (max-width: 940px) {
  .sxl_select_coach_left {
    padding: 26px 15px;
  }
  .sxl_coach_img {
    width: 200px;
    height: 200px;
  }
  .sxl_coach_img img{
    width: 200px;
    height: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .sxl_select_coach_left {
    width: 100%;
  }
  .sxl_field_bg {
    display: none;
  }
  .sxl_field_bg_small {
    display: block;
  }
  .sxl_select_ctas {
    flex-direction: column;
  }
  .sxl_select_ctas a {
    max-width: 100%;
  }
  .sxl_select_cta_white,
  .sxl_select_cta_black,
  .sxl_select_cta_white_small {
    max-width: 100%;
  }
  .sxl_select_cta_white_small {
    display: flex;
  }
}