.sxl_footer {
  display: flex;
  justify-content: center;
}

.sxl_footer_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sxl_logo_item {
  height: 40px;
  min-width: 80px;
  max-width: 400px;
}

.sxl_pravila {
  margin-bottom: 30px;
}

.sxl_pravila p {
  font-family: "Avenir-Roman";
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
}

.sxl_pravila p span {
  text-decoration: underline;
}

.sxl_pravila p a {
  color: #FFFFFF;
}

.sxl_logos {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-bottom: 40px;
}

.sxl_vote_btn {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 84px;
}

.sxl_vote_btn a {
  text-decoration: none;
  font-family: "Avenir-Black";
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  display: inline-block;
  max-width: 222px;
  width: 100%;
  height: 46px;
  border-radius: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #A38E60
}

@media (hover: hover) {}

@media only screen and (max-width: 940px) {}

@media only screen and (max-width: 767px) {
  .sxl_footer {
    padding: 0 15px;
  }

  .sxl_pravila {
    width: 100%;
    text-align: center;
  }

  .sxl_pravila a {
    white-space: nowrap;
  }

  .sxl_logos {
    flex-wrap: wrap;
  }
  .sxl_logos:first-of-type img {
    height: 35px;
  }
}