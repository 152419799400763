.sxl_infos_right {
  position: absolute;
  top: 0;
  left: 110%;
  width: 100%;
  height: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #141414;
  transition: all .3s ease;
  z-index: 99;
}

.sxl_infos_right.sxl_show_right {
  left: 0;
}

.sxl_infos_right_title {
  font-family: "Avenir-Black";
  font-size: 23px;
  line-height: 29px;
  color: white;
  margin-bottom: 18px;
  align-items: center;
  justify-content: space-between;
  display: none;
}

.sxl_back_holder {
  display: none;
  align-items: center;
  justify-content: flex-start;
  font-family: "Avenir-Roman";
  font-size: 16px;
  line-height: 22px;
}

.sxl_back_holder img {
  margin-right: 9px;
  display: block;
  width: 18px;
}

.sxl_back_stats_icon {
  display: none;
}

.sxl_main_info {
  display: flex;
  margin-bottom: 20px;
}

.sxl_img_holder {
  margin-right: 22px;
}

.sxl_img_holder img {
  display: block;
  width: 124px;
  height: 124px;
  border-radius: 50%;
}

.sxl_info {
  flex: 1;
}

.sxl_name {
  font-family: "Avenir-Black";
  font-size: 28px;
  line-height: 36px;
  color: #ffffff;
  margin-bottom: 4px;
}

.sxl_info_position,
.sxl_index,
.sxl_team {
  font-family: "Avenir-Roman";
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 6px;
  color: white
}

.sxl_index {
  display: flex;
  align-items: center;
}

.sxl_team {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.sxl_team img {
  width: 17px;
  margin-right: 4px;
}

.sxl_small_screen_xl_logo {
  display: none;
}

.sxl_stats_title {
  font-family: "Avenir-Black";
  font-size: 23px;
  line-height: 29px;
  color: #ffffff;
  margin-bottom: 10px;
}

.sxl_stats_table {
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
}

/* custom scroll */
/* Works on Firefox */
.sxl_stats_table {
  scrollbar-width: thin;
  scrollbar-color: #A38E60 white;
}

/* Works on Chrome, Edge, and Safari */
.sxl_stats_table::-webkit-scrollbar {
  width: 12px;
  margin-left: 10px;
  left: 10px;
}

.sxl_stats_table::-webkit-scrollbar-track {
  background: white;
}

.sxl_stats_table::-webkit-scrollbar-thumb {
  background-color: #A38E60;
  border-radius: 20px;
  border: 3px solid white;
}

.sxl_stats_table_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 0;
  border-bottom: 1px solid #5c533b;
  padding-right: 30px;
}

.sxl_stats_table_key_bold {
  font-family: "Avenir-Black";
  font-size: 16px;
  color: #ffffff;
}

.sxl_stats_table_key {
  font-family: "Avenir-Roman";
  font-size: 16px;
  color: #ffffff;
}

.sxl_stats_table_value {
  font-family: "Avenir-Black";
  font-size: 16px;
  color: #ffffff;
}

.sxl_info_ctas_holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.sxl_info_back {
  font-family: "Avenir-Book";
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 35px;
  color: white
}

.sxl_info_back img {
  margin-right: 8px;
}

.sxl_info_ctas {
  display: flex;
}

.sxl_info_cta {
  width: 190px;
  height: 46px;
  border-radius: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #A38E60;
  color: #FFFFFF;
  font-family: "Avenir-Black";
  font-size: 16px;
  line-height: 22px;
  padding: 0 8px;
  cursor: pointer;
  transition: all .3s ease;
}

.sxl_info_cta_remove {
  width: 190px;
  height: 46px;
  border-radius: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FF2E63;
  color: #FFFFFF;
  font-family: "Avenir-Black";
  font-size: 16px;
  line-height: 22px;
  padding: 0 8px;
  cursor: pointer;
  transition: all .3s ease;
}


/*  MEDIA */
@media only screen and (max-width: 767px) {
  .sxl_infos_right {
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 9;
    width: 100%;
    background-color: #141414;
    padding: 22px 15px 27px;
    min-height: auto;
    /* height: calc(var(--vh, 1vh) * 100); */
    overflow: auto;
    transition: all .3s ease;
  }

  .sxl_infos_right.sxl_show_right {
    left: 0;
  }

  .sxl_infos_right_title {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 18px;
    display: flex;
  }

  .sxl_back_holder {
    display: flex;
    cursor: pointer;
  }

  .sxl_back_stats_icon {
    display: block;
    width: 61px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
  }

  .sxl_small_add_vote_btn_holder {
    display: block;
  }

  .sxl_main_info {
    position: relative;
    padding: 32px 15px;
    border-radius: 12px;
    box-shadow: 0px 3px 6px #00000029;
  }

  .sxl_small_screen_xl_logo {
    display: block;
    position: absolute;
    right: -5px;
    bottom: -20px;
    pointer-events: none;
    z-index: -1;
  }

  .sxl_info_ctas_holder {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    flex-direction: column;
  }

  .sxl_info_back {
    display: none;
  }

  .sxl_info_ctas {
    width: 100%;
    flex-direction: column;
  }

  .sxl_info_cta {
    width: 100%;
  }

  .sxl_info_cta_remove {
    width: 100%;
  }
}