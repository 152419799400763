.sxl_title {
  font-family: "Avenir-Black";
  font-size: 17px;
  line-height: 21px;
  height: 39px;
  display: table;
  padding: 9px 26px;
  text-align: center;
  z-index: 1;
  background-color: #fff;
  color: #141414;
  box-shadow: 0px 5px 11px #00000029;
  border-radius: 20px;
  margin: auto;
  margin-bottom: -10px;
}

.sxl_best_coach_wrap {
  display: flex;
  justify-content: center;
  height: 604px;
  max-width: 510px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 23px;
  overflow: hidden;
  margin: auto;
  margin-bottom: 42px;
}

.sxl_best_coaches {
  position: relative;
  max-width: 510px;
  width: 100%;
  padding: 40px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sxl_field_bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.sxl_field_bg_small {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.sxl_selected_coach {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.sxl_best_coach {
  width: 100%;
  height: 261px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;
}

.sxl_coach_item {
  flex-direction: column;
  align-items: center;
  align-self: flex-end;
}

.sxl_coach_img {
  position: relative;
  width: 78px;
  height: 78px;
  border-radius: 50%;
  margin: auto;
  margin-bottom: 3px;
}

.sxl_coach_img .sxl_pl_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 78px;
  height: 78px;
  object-fit: cover;
  object-position: center;
}

.sxl_coach_item .sxl_coach_img .sxl_pl_img {
  width: 78px;
  height: 78px;
}

.sxl_coach_item .sxl_coach_img .sxl_ribon {
  position: absolute;
  right: -4px;
  top: -10px;
  z-index: 0;
  width: 34px;
}

.sxl_coach_last_name {
  background: #141414;
  color: #fff;
  padding: 3px 10px;
  border-radius: 16px;
  font-family: "Avenir-Black";
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  display: table;
  margin: auto;
}

.sxl_coach_item:nth-child(2) {
  align-self: flex-start;
}

.sxl_coach_item:nth-child(2) .sxl_coach_img {
  width: 188px;
  height: 188px;
  margin-bottom: 9px;
}

.sxl_coach_item:nth-child(2) .sxl_coach_img .sxl_pl_img {
  width: 188px;
  height: 188px;
  border: 4px solid #FEA929;
  border-radius: 50%;
  box-shadow: 0px 0px 90px #fea929;
}

.sxl_coach_item:nth-child(2) .sxl_coach_img .sxl_ribon {
  position: absolute;
  right: 30px;
  top: -12px;
  z-index: 0;
  width: 38px;
}

.sxl_coach_item:nth-child(2) .sxl_coach_last_name {
  font-size: 16px;
  padding: 5px 12px;
}

/* select */
.sxl_select_holder_sort {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1;
}

.sxl_select_holder_sort select {
  width: 100%;
  height: 24px;
  border: 1px solid transparent;
  border-radius: 17px;
  background: #fff3;
  font-family: "Avenir-Roman";
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  outline: none;
  margin-bottom: 10px;
  cursor: pointer;
}

.sxl_select_holder_sort select option {
  font-family: "Avenir-Roman";
  font-size: 16px;
  line-height: 22px;
  color: #141414;
}

.sxl_select_holder_sort select option:first-of-type {
  display: none;
}

/* select period */
.sxl_select_holder_period {
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 1;
}

.sxl_select_holder_period select {
  width: 100%;
  height: 24px;
  border: 1px solid transparent;
  border-radius: 17px;
  background: #fff3;
  font-family: "Avenir-Roman";
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  outline: none;
  margin-bottom: 10px;
  cursor: pointer;
}

.sxl_select_holder_period select option {
  font-family: "Avenir-Roman";
  font-size: 16px;
  line-height: 22px;
  color: #141414;
}

.sxl_select_holder_period select option:first-of-type {
  display: none;
}

/*  MEDIA */
@media only screen and (max-width: 940px) {
  .sxl_best_coaches {
    padding: 80px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .sxl_title {
    width: auto;
    position: absolute;
    left: 51%;
    transform: translateX(-50%);
    white-space: nowrap;
    top: 24px;
  }

  .sxl_best_holder {
    position: relative;
  }

  .sxl_best_coach_wrap {
    border-radius: 0;
    min-height: 604px;
    height: auto;
    max-width: 100%;
  }

  .sxl_best_coaches {
    max-width: 100%;
  }

  .sxl_field_bg {
    display: none;
  }

  .sxl_field_bg_small {
    display: block;
  }

  .sxl_best_coach {
    flex-wrap: wrap;
  }

  .sxl_coach_item:nth-child(1) {
    order: 2;
    margin-right: 30px;
  }

  .sxl_coach_item:nth-child(2) {
    order: 1;
    width: 100%;
  }

  .sxl_coach_item:nth-child(3) {
    order: 3;
    margin-left: 30px;
  }

  .sxl_select_holder_sort {
    top: 80px;
    right: 15px;
  }

  .sxl_select_holder_period {
    top: 80px;
    left: 15px;
  }
}