.sxl_home_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  padding: 40px 0;
}

.sxl_main_logo {
  text-align: center;
  margin-bottom: 72px;
}

.sxl_main_logo img {
  display: block;
}

.logo {
  width: 360px;
  height: 86px;
}

.sxl_title_msg {
  text-align: center;
  margin-bottom: 42px;
  margin-top: 22px;
  color: white;
}

.sxl_title_msg h1 {
  font-family: "Avenir-Black";
  font-size: 46px;
  line-height: 46px;
  color: white;
  margin-bottom: 10px;
}

.sxl_title_msg h3 {
  font-family: "Avenir-Black";
  font-size: 20px;
  line-height: 36px;
  color: white;
}

.sxl_title_msg p {
  font-family: "Avenir-Roman";
  font-size: 16px;
  line-height: 22px;
  color: white;
}

.sxl_title_msg p span {
  text-decoration: underline;
  white-space: nowrap;
}

.telemach_logo {
  height: 35px;
  margin-left: 6px;
}

.sxl_vote_btn {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 84px;
}

.sxl_vote_btn a {
  text-decoration: none;
  font-family: "Avenir-Black";
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  display: inline-block;
  max-width: 222px;
  width: 100%;
  height: 55px;
  min-width: 266px;
  border-radius: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d4ab40;
}

.sxl_logos_holder {
  margin-bottom: 40px;
}

.sxl_logos {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-bottom: 40px;
}

.sxl_logos:last-of-type {
  margin-bottom: 0;
}

.sxl_logo_item {
  height: 40px;
}

.sxl_pravila p {
  font-family: "Avenir-Roman";
  font-size: 14px;
  line-height: 19px;
  color: #000000;
}

.sxl_pravila p span {
  text-decoration: underline;
}

.sxl_pravila p a {
  color: #000000;
  white-space: nowrap;
}

@media (hover: hover) {
  .sxl_vote_btn a:hover {
    opacity: 0.8;
  }
}


/*  MEDIA */
@media only screen and (max-width: 940px) {}

@media only screen and (max-width: 700px) {
  .sxl_main_logo {
    margin-bottom: 50px;
  }

  .sxl_main_logo img {
    width: auto;
  }

  .sxl_title_msg {
    margin-bottom: 56px;
  }

  .sxl_title_msg h1 {
    margin-bottom: 0px;
    font-size: 36px;
  }

  .sxl_title_msg h3 {
    font-size: 18px;
  }

  .telemach_logo {
    height: 30px;
  }

  .sxl_logos_holder {
    margin-bottom: 60px;
  }

  .sxl_logos {
    gap: 28px;
    margin-bottom: 34px;
    flex-wrap: wrap;
  }

  .sxl_logos:first-of-type img {
    height: 35px;
  }

  .sxl_logos:last-of-type img:nth-child(1),
  .sxl_logos:last-of-type img:nth-child(2) {
    height: 41px;
  }

  .sxl_logos:last-of-type img:nth-child(3) {
    height: 21px;
  }

  .sxl_logos:last-of-type img:nth-child(4) {
    height: 31px;
  }

  .sxl_logos:last-of-type img:nth-child(5) {
    height: 51px;
  }

  .sxl_pravila {
    text-align: center;
  }
}

@media only screen and (max-width: 486px) {

  .sxl_title_msg h1 {
    font-size: 26px;
    margin-bottom: -5px;
  }

  .sxl_title_msg h3 {
    font-size: 14px;
  }

  .telemach_logo {
    height: 20px;
  }

}