.sxl_home_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  padding: 40px 0;
}
.sxl_main_logo {
  text-align: center;
  margin-bottom: 72px;
}
.sxl_main_logo img {
  display: block;
}

.sxl_title_msg {
  text-align: center;
  margin-bottom: 42px;
}
.sxl_title_msg h1 {
  font-family: "Avenir-Black";
  font-size: 26px;
  line-height: 36px;
  color: #ffffff;
  margin-bottom: 20px;
}
.sxl_title_msg h1 span {
  color: #A38E60;
  text-decoration: underline;
}

.sxl_vote_btn {
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.sxl_vote_btn div{
  text-decoration: none;
  font-family: "Avenir-Black";
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  display: inline-block;
  max-width: 222px;
  width: 100%;
  height: 46px;
  border-radius: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #A38E60;
}
.sxl_vote_btn img {
  margin-right: 10px;
}
.sxl_logos_holder{
  margin-bottom: 94px;
}
.sxl_logos {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-bottom: 40px;
}
.sxl_logos:last-of-type {
  margin-bottom: 0;
}

.sxl_logo_item {
  height: 40px;
}

.sxl_pravila p{
  font-family: "Avenir-Roman";
  font-size: 14px;
  line-height: 19px;
  color: #FFFFFF;
}
.sxl_pravila p span{
  text-decoration: underline;
}
.sxl_pravila p a{ 
  color: #FFFFFF;
  white-space: nowrap;
}

@media (hover: hover) {
  .sxl_vote_btn a:hover {
    opacity: 0.8;
  }
}


/*  MEDIA */
@media only screen and (max-width: 940px) {
}

@media only screen and (max-width: 700px) {
  .sxl_main_logo {
    margin-bottom: 50px;
  }
  .sxl_main_logo img {
    width: 188px;
  }
  .sxl_title_msg {
    margin-bottom: 56px;
  }
  .sxl_title_msg h1 {
    margin-bottom: 4px;
  }
  .sxl_logos_holder{
    margin-bottom: 60px;
  }
  .sxl_logos {
    gap: 28px;
    margin-bottom: 34px;
    flex-wrap: wrap;
  }
  .sxl_logos:first-of-type img {
    height: 35px;
  }
  .sxl_logos:last-of-type img:nth-child(1),
  .sxl_logos:last-of-type img:nth-child(2) {
    height: 41px;
  }
  .sxl_logos:last-of-type img:nth-child(3) {
    height: 21px;
  }
  .sxl_logos:last-of-type img:nth-child(4) {
    height: 31px;
  }
  .sxl_logos:last-of-type img:nth-child(5) {
    height: 51px;
  }
  .sxl_pravila {
    text-align: center;
  }

  .sxl_vote_btn {
    flex-direction: column;
    padding: 0 15px;
  }
  .sxl_vote_btn a {
    display: flex;
    width: 100%;
    max-width: 100%;
  }
}